import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { Col, Card, Accordion } from "react-bootstrap";
import {dashboardName} from '../../../baseURL';
import {SetBackgroundTheme} from "../../setBackgroundTheme";
import Cookies from 'js-cookie';

const ExchangesConnect = () => {
  const [activeDefault, setActiveDefault] = useState(0);

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    SetBackgroundTheme(changeBackground, Cookies);
  }, []);

  const defaultAccordion = [
    {
      title: "What exactly do you offer?",
      text: "We have a trading algorithm that performs very well. We provide you access to our algorithm through our user-friendly interface.",
      bg: "primary",
    },
    {
      title: "What are the costs?",
      text: "You don't pay anything upfront! We only take a fee when you make a profit. We'll bill you 20% of your earnings every month. For instance, if you make $100 in profit trading on "+dashboardName+", you'll receive a bill for $20.",
      bg: "info",
    },
    {
      title: "Is there a minimum investment to get started?",
      text: "Yes, you need at least 100 USDT.",
      bg: "success",
    },
    {
      title: "How are my funds protected? What prevents you from taking my assets?",
      text: "We can't take your assets. When you give us a Binance API key, it's set up without withdrawal permissions. This means we can only use the key to execute trades; we can't withdraw any funds.",
      bg: "success",
    },
    {
      title: "I am a U.S customer and Binance is not supported in the U.S.",
      text: "Binance.US is not supported.",
      bg: "success",
    },
    {
      title: "I would like to use my own trading strategy with the bot.",
      text: "We're not quite ready for that yet. Our focus is on giving everyday people the chance to benefit from crypto trading.",
      bg: "success",
    },
  ];

  return (
    <>
      <div className="row">

        <Col xl="12">
          <Card>
            <Card.Header className="d-block card-header">
              <h4 className="card-title">Get Started</h4>
              <Card.Text className="m-0 subtitle">
                Frequently Asked Questions
              </Card.Text>
            </Card.Header>
            <Card.Body className="card-body">
              {/* <!-- Default accordion --> */}
              <Accordion
                className="accordion accordion-primary accordion-header-bg"
                defaultActiveKey="0"
              >
                {defaultAccordion.map((d, i) => (
                  <div className="accordion__item" key={i}>
                    <Accordion.Toggle
                      as={Card.Text}
                      eventKey={`${i}`}
                      className={`accordion__header accordion__header--info rounded-lg ${
                        activeDefault === i ? "" : "collapsed"
                      }`}
                      onClick={() =>
                        setActiveDefault(activeDefault === i ? -1 : i)
                      }
                    >
                      <span className="accordion__header--text">{d.title}</span>
                      <span className="accordion__header--indicator"></span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`${i}`}>
                      <div className="accordion__body--text">{d.text}</div>
                    </Accordion.Collapse>
                  </div>
                ))}
              </Accordion>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </>
  );
};

export default ExchangesConnect;
