import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import {SetBackgroundTheme} from "../../setBackgroundTheme";

import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

import GaugeChart from 'react-gauge-chart';

const ExchangesConnect = () => {
  const [fearGearValue, setFearGearValue] = useState(0);
  const [fearGearValueClassification, setFearGearValueClassification] = useState('loading...');
  const [fearGearValueYesterday, setFearGearValueYesterday] = useState('loading...');
  const [fearGearValueWeek, setFearGearValueWeek] = useState('loading...');
  
  async function getFearGreed() {
    const token = Cookies.get('token');
    const data = { token: token }
    const response = await axios.post(baseURL+'/api/get_crypto_fear_and_greed', data)
    if(response.data.status === 'ok') {
      const result = response.data.result;
      
      const fear_gear_value = parseInt(result.day.value) / 100;
      setFearGearValue(fear_gear_value);
      setFearGearValueClassification(result.day.value_classification);
      setFearGearValueYesterday(result.yesterday.value);
      setFearGearValueWeek(result.week.value);
    }
  }

  useEffect(() => {
    getFearGreed();
    // eslint-disable-next-line
  }, []);


  return (
    <div>

      <div className="col-12">
        <div className="card overflow-hidden">
          <div className="card-header d-block d-sm-flex border-0">
            <div>
              <h4 className="fs-20 text-black">Crypto Fear & Greed Index</h4>
              <p className="mb-0 fs-12">
                The index ranges from 0 (Extreme Fear) to 100 (Extreme Greed), reflecting crypto market sentiment. A low-value signals over-selling, while a high value warns of a potential market correction.
              </p>
            </div>
            <div className="card-action card-tabs mt-3 mt-sm-0">
              
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-6">
                <GaugeChart 
                  id="gauge-chart1"
                  nrOfLevels={30}
                  percent={fearGearValue} // The value for the gauge (0 to 1)
                  arcWidth={0.3}
                  textColor="#000000"
                  needleColor="#000000"
                  colors={['#FF0000', '#FFFF00', '#00FF00']}
                />

                <h4 className="text-center" style={{background: `linear-gradient(to right, green ${fearGearValue * 100}%, red ${fearGearValue * 100}%)`, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>{fearGearValueClassification}</h4>
              </div>

              <div className="col-12 col-md-4 col-lg-6 text-center">
                <div className="row">
                  <div className="col-6 col-md-12">
                    <h2 className="text-success mt-4">{fearGearValueYesterday}</h2>
                    <span>Yesterday</span>
                  </div>

                  <div className="col-6 col-md-12">
                    <h2 className="text-success mt-4">{fearGearValueWeek}</h2>
                    <span>Last Week</span>
                  </div>
                </div>
              </div>

            </div>
            
          </div>
        </div>
      </div>

    </div>
  );
};

export default ExchangesConnect;
