import React, { useEffect, useState } from "react";
import { Badge, Pagination, Alert } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

const Invoices = ( {setBasicModal} ) => {
  const [commissions, setCommissions] = useState([]);

  const [active, setActive] = useState(1);
  const [maxPage, setMaxPage] = useState(2);
  const [pageItems, setPageItems] = useState([{p:1}]);
  const [loadingBadge, setLoadingBadge] = useState(false);
  const [maxAvailablePage, setMaxAvailablePage] = useState(1);

  useEffect(() => {
    getCommissions(1);
  }, []);

  function setNavigationLinks(max_page) {
    var page_items_tmp = [];
    for (let number = active; number <= max_page; number++) {
      page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
  }

  function changePage(page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    var max_page = maxPage;
    if(page_number > 5) {
      number_first = page_number - 5;
      max_page = max_page + number_first;
      if(max_page > maxAvailablePage){
        max_page = maxAvailablePage;
      }
    }

    var page_items_tmp = [];
    for (let number = number_first; number <= max_page; number++) {
        page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
    getCommissions(page_number);
  }

  function changePageNoReload(page, max_page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    if(page_number > 5) {
        number_first = page_number - 5;
        max_page = max_page + number_first;
    }

    var page_items_tmp = [];
    for (let number = number_first; number <= max_page; number++) {
        page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
  }

  function changePagePrev() {
    var next_page = active - 1;
    if(next_page < 1) {
      next_page = 1;
    }
    setActive(next_page);
    changePage(next_page);
  }

  function changePageNext() {
    const next_page = active + 1;
    if(next_page <= maxAvailablePage) {
      setActive(next_page);
      changePage(next_page);
    }
  }

  function convertTimestampToYmd(timestamp) {
    // // Create a new Date object from the timestamp
    // const date = new Date(timestamp * 1000);

    // // Extract the date components
    // const year = date.getFullYear();
    // const month = ('0' + (date.getMonth() + 1)).slice(-2);
    // const day = ('0' + date.getDate()).slice(-2);
    // const hours = ('0' + date.getHours()).slice(-2);
    // const minutes = ('0' + date.getMinutes()).slice(-2);
    // const seconds = ('0' + date.getSeconds()).slice(-2);

    // // Construct the formatted date string
    // const formattedDate = year + '.' + month + '.' + day;

    // Convert timestamp to Date object
    let date = new Date(timestamp * 1000);

    // Define options for formatting the date
    let options = { 
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        timeZone: 'UTC' 
    };

    // Format the date
    let formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  async function getCommissions(page_number) {
    const data = {
      token: Cookies.get('token'),
      page: page_number
    }

    try {
      const response = await axios.post(baseURL+'/api/invoices/get_commissions', data);
      if(response.data.status === 'ok') {
        setCommissions(response.data.commissions);
        setNavigationLinks(response.data.max_page);
        setMaxAvailablePage(response.data.max_page);
        setMaxPage(response.data.max_page);
        changePageNoReload(page_number, response.data.max_page);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }

    setLoadingBadge(false);
  }

  return (
    <div className="col-12">
      <div className="card overflow-hidden">
        <div className="card-header d-block d-sm-flex border-0">
          <div>
            <h4 className="fs-20 text-black">Commission history</h4>
            <p className="mb-0 fs-12">
              
            </p>
          </div>
          <div className="card-action card-tabs mt-3 mt-sm-0">
            <button className="btn btn-sm btn-success text-white" onClick={() => setBasicModal(true)}>Top Up balance</button>
          </div>
        </div>
        <div className="card-body pt-0">

          {loadingBadge === false && commissions.length === 0 && (
            <Alert variant="primary" className="alert-dismissible fade show">
              There is no commission charge data.
            </Alert>
          )}

          {commissions.length > 0 && (
            <Table responsive className="w-100">
              <div id="example_wrapper" className="dataTables_wrapper">
                  <table
                    id="example"
                    className="display w-100 dataTable"
                    role="grid"
                    aria-describedby="example_info"
                  >
                    <thead>
                        <th>Date</th>
                        <th>Period</th>
                        <th>Profit</th>
                        <th>Commission rate</th>
                        <th>Commission</th>
                        <th>Payment status</th>
                    </thead>
                    <tbody>
                    
                      {commissions &&
                        commissions.map((item, key) => (
                          <tr key={key}>
                            <td role="row" className="wspace-no">{convertTimestampToYmd(item.timestamp)}</td>
                            <td role="row" className="wspace-no">{convertTimestampToYmd(item.timestamp_start)} - {convertTimestampToYmd(item.timestamp_end)} (UTC)</td>
                            <td className="wspace-no">{item.profit.toFixed(4)} USDT</td>
                            <td>{item.commission_rate_percent} %</td>
                            <td>{item.commission.toFixed(4)} USDT</td>
                            <td>
                              {item.paid_status === 0 && (
                                <Badge variant="danger light">Unpaid</Badge>
                              )}
                              {item.paid_status === 1 && (
                                <Badge variant="success light">Paid</Badge>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                        
                    </tbody>
                    {/* <tfoot>
                        <tr role="row">
                          {data.jobsTable.columns.map((d, i) => (
                              <th key={i}>{d}</th>
                          ))}
                        </tr>
                    </tfoot> */}
                  </table>
              </div>
            </Table>
          )}

          {commissions.length > 0 && (
            <div className="">
              <Pagination
                size="sm"
                className={`mt-4 pagination-gutter pagination-primary no-bg pagination-circle`}
              >
                <li className="page-item page-indicator">
                  <Link className="page-link" to="#" onClick={() => changePagePrev()}>
                    <i className="la la-angle-left" />
                  </Link>
                </li>

                {pageItems.length > 0 &&
                  pageItems.map((item, i) => (
                    <Pagination.Item key={i} active={item.p === active ? true : false} onClick={() => {changePage(item.p)}}>
                      {item.p}
                    </Pagination.Item>
                  )
                )}
                
                <li className="page-item page-indicator">
                  <Link className="page-link" to="#" onClick={() => changePageNext()}>
                    <i className="la la-angle-right" />
                  </Link>
                </li>
              </Pagination>

              {loadingBadge === true && (
                <div className="mt-3">
                  <Badge variant="info light">Loading</Badge>
                </div>
              )}
              
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default Invoices;
