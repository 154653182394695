export async function SetBackgroundTheme(changeBackground, Cookies) {
  await delay(100);
  if(Cookies.get('theme') === undefined) {
    changeBackground({ value: "light", label: "Light" })
    Cookies.set('theme', JSON.stringify({ value: "light", label: "Light" }), { expires: 30, path: '/'});
    return "";
  }
  const theme = JSON.parse(Cookies.get('theme'));
  if(theme.value === null || theme.value === undefined) {
    Cookies.set('theme', JSON.stringify({ value: "light", label: "Light" }), { expires: 30, path: '/'});
  }

  if(theme.value === 'light') {
    changeBackground({ value: "light", label: "Light" })
    Cookies.set('theme', JSON.stringify({ value: "light", label: "Light" }), { expires: 30, path: '/'});
  }
  if(theme.value === 'dark') {
    changeBackground({ value: "dark", label: "Dark" })
    Cookies.set('theme', JSON.stringify({ value: "dark", label: "Dark" }), { expires: 30, path: '/'});
  }
}

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);