import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Badge, Alert } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";

import pattern1 from "../../../images/pattern/pattern1.png";
import pattern2 from "../../../images/pattern/pattern2.png";
import loadingGif from "../../../images/loading.gif";

import axios from 'axios';
import { baseURL, stripePubKey, dashboardName } from "../../../baseURL";
import Cookies from 'js-cookie';
import {loadStripe} from '@stripe/stripe-js';

import TransactionHistory from './TransactionHistory.js'

import {SetBackgroundTheme} from "../../setBackgroundTheme";

const License = () => {
  const [basicModal, setBasicModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [licenseActive, setLicenseActive] = useState(0);

  const [paymentMethod, setPaymentMethod] = useState('');
  const [depositAmount, setDepositAmount] = useState(399);
  const [depositAmountCoint, setDepositAmountCoint] = useState(399);
  const [coinPriceUSDT, setCoinPriceUSDT] = useState(1);
  const [paymentID, setPaymentID] = useState(0);

  const [updateTransaction, setUpdateTransaction] = useState(0);
  
  
  

  const [disabledDepositButton, setDisabledDepositButton] = useState(false);

  const [allWalletAddresses, setAllWalletAddresses] = useState([{coin_name: "", wallet_address: "", network: ""}]);
  const [targetWalletAddress, setTargetWalletAddress] = useState('');
  const [targetWalletNetwork, setTargetWalletNetwork] = useState('');
  const [targetWalletCoin, setTargetWalletCoin] = useState('');
  const [paymentCoinSelected, setPaymentCoinSelected] = useState(false);
  const [disabledPaymentConfirmButton, setDisabledPaymentConfirmButton] = useState(false);
  

  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);

  const [sendingWalletAddress, setSendingWalletAddress] = useState('');
  const [disabledUpdatPaymentButton, setDisabledUpdatPaymentButton] = useState(false);
  
  
  
  
  

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getLicenseInfo();
    getWalletAddresses();

    SetBackgroundTheme(changeBackground, Cookies);
  }, []);

  async function getLicenseInfo() {
    const data = {
      token: Cookies.get('token')
    }

    try {
      const response = await axios.post(baseURL+'/api/lifetime_license/get_user_info', data);
      console.log(response.data);
      if(response.data.status === 'ok') {
        setLicenseActive(response.data.lifetime_license);
        setDepositAmount(response.data.deposit_amount);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }

    setLoading(false);
  }

  async function getWalletAddresses() {
    const data = {
      token: Cookies.get('token')
    }

    try {
      const response = await axios.post(baseURL+'/api/invoices/crypto/get_wallet_addresses', data);
      console.log(response.data);
      if(response.data.status === 'ok') {
        setAllWalletAddresses(response.data.payment_wallet_address);
        
        for(const p of response.data.payment_wallet_address) {
          const coin_network = p.coin_name + '_' + p.network;
          setPaymentMethod(coin_network);
          break;
        }
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }
  }

  async function selectPaymentMethod() {
    setDisabledDepositButton(true);

    if(paymentMethod === 'card') {
      const data = {
        token: Cookies.get('token'),
        price: depositAmount,
        license_type: 'lifetime'
      }

      try {
        const response = await axios.post(baseURL+'/api/invoices/stripe/create_payment', data);
        if(response.data.status === 'ok') {
          const stripe = await loadStripe(stripePubKey);
          stripe.redirectToCheckout({
            sessionId: response.data.session_id
          })
        } else {
          // setError(response.data.text);
        }
      } catch(err) {

      }
    }

    for(const w of allWalletAddresses) {
      const coin_network = w.coin_name + '_' + w.network;
      if(coin_network === paymentMethod) {
        setTargetWalletAddress(w.wallet_address);
        setTargetWalletNetwork(w.network);
        setTargetWalletCoin(w.coin_name);
        setPaymentCoinSelected(true);
      }
    }

    if(paymentMethod === 'DAI_ETH (Ethereum, ERC20)') {
      setDepositAmountCoint(depositAmount);
    }

    if(paymentMethod === 'DAI_MATIC (Polygon)') {
      setDepositAmountCoint(depositAmount);
    }

    if(paymentMethod === 'Ethereum_ETH (Ethereum, ERC20)') {
      const price_eth = 3000;
      setCoinPriceUSDT(price_eth);
      const d = Math.round(depositAmount / price_eth * 100000) / 100000;
      setDepositAmountCoint(d);
    }

    if(paymentMethod === 'USDT_ETH (Ethereum, ERC20)') {
      setDepositAmountCoint(depositAmount);
    }

    setDisabledDepositButton(false);
  }

  async function createPayment() {
    setDisabledPaymentConfirmButton(true);

    const data = {
      token: Cookies.get('token'),
      coin_name: targetWalletCoin,
      wallet_address_to: targetWalletAddress,
      wallet_address_to: targetWalletAddress,
      wallet_network: targetWalletNetwork,
      price: coinPriceUSDT,
      usd: depositAmount,
      coins: depositAmountCoint
    }

    try {
      const response = await axios.post(baseURL+'/api/invoices/crypto/create_payment', data);
      if(response.data.status === 'ok') {
        setPaymentID(response.data.payment_id);
        setPaymentCompleted(true);
        setUpdateTransaction(updateTransaction+1);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }
    setDisabledPaymentConfirmButton(false);
  }

  async function updatePayment() {
    setDisabledUpdatPaymentButton(true);

    const data = {
      token: Cookies.get('token'),
      payment_id: paymentID,
      wallet_address_from: sendingWalletAddress
    }

    try {
      const response = await axios.post(baseURL+'/api/invoices/crypto/update_payment', data);
      if(response.data.status === 'ok') {
        setBasicModal(false);
        setUpdateTransaction(updateTransaction+1);

        setPaymentCoinSelected(false);
        setPaymentCompleted(false);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }

    setDisabledUpdatPaymentButton(false);
  }

  async function copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedToClipboard(true);
      await delay(3000);
      setCopiedToClipboard(false);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  return (
    <div className="row">

      {loading === true && (
        <div className="col-12">
          <Badge variant="info light">Loading...</Badge>
        </div>
      )}

      {loading === false && (
        <>
          <div className="col-12 p-0 mb-4">
            <div className="row">
            <div className="col-12 col-sm-6 mb-2">
                <div className="items">
                  <div
                    className="wallet-card bg-primary"
                    style={{ backgroundImage: `url(${pattern2})` }}
                  >
                    <div className="head">
                      <p className="fs-14 text-white mb-0 op6 font-w100">Lifetime License status</p>
                      {/* <span>${unpaidCommission}</span> */}
                      {licenseActive === 1 && (
                        <button className="mt-2 btn btn-xs btn-success text-white">Activated</button>
                      )}
                      {licenseActive === 0 && (
                        <button className="mt-2 btn btn-xs btn-warning text-white">Not Active</button>
                      )}
                    </div>
                    {/* <div className="wallet-footer">
                      <span className="fs-14">444 221 224 ***</span>
                      <img src={cardLogo2} alt="" />
                    </div> */}
                  </div>
                </div>
              </div>
              {licenseActive === 0 && (
                <div className="col-12 col-sm-6 mb-2">
                  <div className="items">
                    <div
                      className="wallet-card bg-info"
                      style={{ backgroundImage: `url(${pattern1})` }}
                    >
                      <div className="head">
                        <p className="fs-14 text-white mb-0 op6 font-w100">Action</p>
                        <button className="mt-2 btn btn-xs btn-success text-white" onClick={() => setBasicModal(true)}>Activate Lifetime License</button>
                      </div>
                      {/* <div className="wallet-footer">
                        <span className="fs-14">444 221 224 ***</span>
                        <img src={cardLogo} alt="" />
                      </div> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <TransactionHistory setBasicModal={setBasicModal} depositButtonText="Activate Lifetime License" showDepositButton={licenseActive === 0 ? true : false} updateTransaction={updateTransaction} />
          
          <Modal className="fade" show={basicModal}>
            <Modal.Header>
              <Modal.Title>Activate Lifetime License</Modal.Title>
              <Button
                variant=""
                className="close"
                onClick={() => {setBasicModal(false); setPaymentCoinSelected(false); setPaymentCompleted(false);} }
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>

              {/* <label className="col-sm-3 col-form-label">Deposit</label> */}

              {paymentCoinSelected === false && (
                <>
                  <p className="text-center">Deposit {depositAmount} USD to active the lifetime license.</p>

                  <div className="form-group row text-center">
                    <select className="form-control text-center" onChange={(e) => setPaymentMethod(e.target.value)}>
                      {/* <option value="card" selected={paymentMethod === 'card' ? true : false}>Credit/Debit Card</option> */}
                      {allWalletAddresses.length > 0 &&
                        allWalletAddresses.map((item, i) => (
                          <option key={i} value={`${item.coin_name}_${item.network}`} selected={paymentMethod === `${item.coin_name}_${item.network}` ? true : false}>(Crypto) {item.coin_name} - Network: {item.network}</option>
                        )
                      )}
                    </select>
                  </div>

                  <div className="form-group row">
                    <button className="btn btn-md btn-info btn-block mt-2" onClick={() => selectPaymentMethod()} disabled={disabledDepositButton}>Next</button>
                  </div>
                </>
              )}

              {paymentCoinSelected === true && paymentCompleted === false && (
                <>
                  <div className="form-group">
                    <label className="mb-1 ">
                      <strong>Recipient's Wallet Address:</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder
                      value={targetWalletAddress}
                      disabled={true}
                    />
                    <p className="text-left">
                    {copiedToClipboard === false && (
                      <small onClick={() => copyToClipboard(targetWalletAddress)} style={{cursor: 'pointer', textDecoration: 'underline'}} className="">(Click to copy)</small>
                    )}
                    {copiedToClipboard === true && (
                      <small className="text-success">The wallet address has been copied to the clipboard.<br/></small>
                    )}
                    </p>
                  </div>

                  <div className="form-group">
                    <label className="mb-1 ">
                      <strong>Network:</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder
                      value={targetWalletNetwork}
                      disabled={true}
                    />
                  </div>

                  <b>The recipient should receive: </b> {depositAmountCoint} {targetWalletCoin}<br/>

                  <button className="mt-3 mb-3 btn btn-block btn-warning" onClick={() => createPayment()} disabled={disabledPaymentConfirmButton}>
                    {disabledPaymentConfirmButton === false && (
                      <span>Confirm that I transferred the {targetWalletCoin}<br/>(Next Step {'>'})</span>
                    )}
                    {disabledPaymentConfirmButton === true && (
                      <span><img alt="" src={loadingGif} width="18" /></span>
                    )}
                  </button>

                  <Alert variant="warning" className="alert-dismissible fade show">
                    Important! If the recipient receives a different amount of coins, the transaction may process with significant delays. Please ensure that the recipient receives the exact amount of coins.
                  </Alert>
                </>
              )}

              {paymentCoinSelected === true && paymentCompleted === true && (
                <>
                  <Alert variant="primary" className="alert-dismissible fade show">
                    To track your transaction on the blockchain, please provide the wallet address you used for this transaction or add the Txid.
                  </Alert>

                  <div className="form-group">
                    <label className="mb-1 ">
                      <strong>Your Wallet Address or Txid:</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder
                      onChange={(e) => {setSendingWalletAddress(e.target.value)}}
                      value={sendingWalletAddress}
                    />
                  </div>

                  <button className="mt-3 mb-3 btn btn-block btn-warning" onClick={() => updatePayment()} disabled={disabledUpdatPaymentButton}>
                    {disabledUpdatPaymentButton === false && (
                      <span>Save</span>
                    )}
                    {disabledUpdatPaymentButton === true && (
                      <span><img alt="" src={loadingGif} width="18" /></span>
                    )}
                  </button>
                </>
              )}

            </Modal.Body>
            {/* <Modal.Footer>
              <Button
                onClick={() => setBasicModal(false)}
                variant="danger light"
              >
                Close
              </Button>
              <Button variant="primary">Save changes</Button>
            </Modal.Footer> */}
          </Modal>
        </>
      )}

    </div>
  );
};

export default License;
