import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import logo from "../../images/logo/logo_full.png";
import loadingGif from "../../images/loading.gif";
import axios from 'axios';
import { baseURL } from "../../baseURL";

const Register = ({ history }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    setDisabledButton(true);
    setLoading(true);

    const queryParameters = new URLSearchParams(window.location.search);
    const passwordResetToken = queryParameters.get("token");
    const email = queryParameters.get("email");

    if(password.length === 0) {
      setError("The Password field is blank.")
      setDisabledButton(false);
      setLoading(false);
      return "";
    }
    if(confirmPassword.length === 0) {
      setError("The Confirm Password field is blank.")
      setDisabledButton(false);
      setLoading(false);
      return "";
    }
    if(password !== confirmPassword) {
      setError("The Password and Confirm Password do not match.")
      setDisabledButton(false);
      setLoading(false);
      return "";
    }

    const data = {
      token: passwordResetToken,
      email: email,
      password: password,
      confirmPassword: confirmPassword
    }

    try {
      const response = await axios.post(baseURL+'/api/user/password/reset-password', data);
      if(response.data.status === 'ok') {
        history.push("/page-login");
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    
    setDisabledButton(false);
    setLoading(false);
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={logo} width="120" alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Password Reset</h4>

                    {error !== "" && (
                      <Alert variant="danger" className="alert-dismissible fade show">
                        {error}
                      </Alert>
                    )}
                    
                    <form onSubmit={(e) => submitHandler(e)}>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          onChange={(e) => {setPassword(e.target.value); setError('')}}
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Confirm Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Confirm Password"
                          onChange={(e) => {setConfirmPassword(e.target.value); setError('')}}
                        />
                      </div>
                      <div className="text-center mt-4">
                        <button type="submit" className="btn btn-primary btn-block justify-content-center align-items-center h-100" disabled={disabledButton}>
                          {loading === false && (
                            <span>Reset Password</span>
                          )}
                          {loading === true && (
                            <span><img alt="" src={loadingGif} width="18" /></span>
                          )}
                          {/* <span>Sign up</span> */}
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="">
                        Already have an account?{" "}
                        <Link className="text-primary" to="/page-login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
