import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import {
  Table,
  Badge,
  Pagination,
  Alert
} from "react-bootstrap";
import pattern1 from "../../../images/pattern/pattern1.png";
import pattern2 from "../../../images/pattern/pattern2.png";

import axios from 'axios';
import { baseURL, basePromoURL } from "../../../baseURL";
import Cookies from 'js-cookie';

import {SetBackgroundTheme} from "../../setBackgroundTheme";
import InvitedUsers from './InvitedUsers';

const ExchangesConnect = () => {
  const [commissionRate, setCommissionRate] = useState(0);
  const [invitedUsers, setInvitedUsers] = useState(0);
  const [invitedActiveUsers, setInvitedActiveUsers] = useState(0);
  const [referralBalance, setReferralBalance] = useState(0);
  const [level, setLevel] = useState(0);
  const [referralUsername, setReferralUsername] = useState('');
  const [newReferralUsername, setNewReferralUsername] = useState('');
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [referralUsernameUpdateLoading, setReferralUsernameUpdateLoading] = useState(false);
  const [referralUsernameError, setReferralUsernameError] = useState('');
  const [loading, setLoading] = useState(true);

  
  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getReferralInfo();

    SetBackgroundTheme(changeBackground, Cookies);

    // eslint-disable-next-line
  }, []);

  async function getReferralInfo() {
    setLoading(true);
    const data = {
      token: Cookies.get('token')
    }

    try {
      const response = await axios.post(baseURL+'/api/referral/get_info', data);
      if(response.data.status === 'ok') {
        setCommissionRate(response.data.commission_rate);
        setLevel(response.data.level);
        setReferralUsername(response.data.referral_username);
        setNewReferralUsername(response.data.referral_username);
        setInvitedUsers(response.data.invited_users);
        setInvitedActiveUsers(response.data.invited_active_users);
        setReferralBalance(response.data.referral_balance);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }

    setLoading(false);
  }

  async function copyToClipboard() {
    const referral_link = basePromoURL + "/?r=" + referralUsername
    try {
      await navigator.clipboard.writeText(referral_link);
      setCopiedToClipboard(true);
      await delay(3000);
      setCopiedToClipboard(false);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  function changeReferralUsername(value) {
    setReferralUsernameError('');
    var value_new = value.replace(/[^a-zA-Z]/g, '');
    if(value_new.length > 20) {
      value_new = value_new.slice(0, 20);
    }
    value_new = value_new.toLowerCase();
    setNewReferralUsername(value_new);
  }

  async function updateReferralUsername() {
    setReferralUsernameError('');
    setReferralUsernameUpdateLoading(true);
    const data = {
      token: Cookies.get('token'),
      referral_username: newReferralUsername
    }

    try {
      const response = await axios.post(baseURL+'/api/referral/update_referral_username', data);
      if(response.data.status === 'ok') {
        await getReferralInfo();
      } else {
        setReferralUsernameError(response.data.text);
      }
    } catch(err) {

    }

    setReferralUsernameUpdateLoading(false);
  }

  return (
    <>

      {loading === true && (
        <Badge variant="info light">Loading...</Badge>
      )}

      {loading === false && (
        <>
          <div className="row">

            <div className="col-12 p-0 mb-4">
              <div className="row">

                <div className="col-12 col-md-6 mb-2">
                  <div className="items">
                    <div
                      className="wallet-card bg-primary"
                      style={{ backgroundImage: `url(${pattern2})` }}
                    >
                      <div className="head">
                        <p className="fs-14 text-white mb-0 op6 font-w100">Invited users</p>
                        <span>{invitedUsers}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <div className="items">
                    <div
                      className="wallet-card bg-primary"
                      style={{ backgroundImage: `url(${pattern1})` }}
                    >
                      <div className="head">
                        <p className="fs-14 text-white mb-0 op6 font-w100">Invited active users</p>
                        <span>{invitedActiveUsers}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <div className="items">
                    <div
                      className="wallet-card bg-info"
                      style={{ backgroundImage: `url(${pattern2})` }}
                    >
                      <div className="head">
                        <p className="fs-14 text-white mb-0 op6 font-w100">Your commission</p>
                        <span>{commissionRate}%</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <div className="items">
                    <div
                      className="wallet-card bg-info"
                      style={{ backgroundImage: `url(${pattern2})` }}
                    >
                      <div className="head">
                        <p className="fs-14 text-white mb-0 op6 font-w100">Referral Balance</p>
                        <span>${referralBalance}</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div className="row">

            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Change username</h4>
                </div>
                <div className="card-body">

                  <div className="row">
                    <div className="col-12">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">{basePromoURL + "/?r="}</div>
                        </div>
                        <input type="text" className="form-control" placeholder="Username" value={newReferralUsername} onChange={(e) => changeReferralUsername(e.target.value)} />
                      </div>
                    </div>

                    <div className="col-12">
                      <button className="btn btn-sm btn-info btn-block mt-2" onClick={() => updateReferralUsername()} disabled={referralUsernameUpdateLoading}>
                        {referralUsernameUpdateLoading === false && (
                          <span>Save</span>
                        )}
                        {referralUsernameUpdateLoading === true && (
                          <span>...</span>
                        )}
                      </button>

                      {/* setReferralUsernameError */}
                      <p className="text-center text-danger">
                        <small>{referralUsernameError}</small>
                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Invitation link</h4>
                </div>
                <div className="card-body">
                  <p className="text-center">
                    <button onClick={() => copyToClipboard()} className="btn btn-sm btn-info text-center">{basePromoURL + "/?r=" + referralUsername}</button>
                    <br/>
                    {copiedToClipboard === false && (
                      <small onClick={() => copyToClipboard()} style={{cursor: 'pointer'}}>Click to copy</small>
                    )}
                    {copiedToClipboard === true && (
                      <small className="text-success">The link has been copied to the clipboard.<br/></small>
                    )}
                  </p>
                  <p className="text-center">
                    <small>Share this invitation link and start to earn bonuses.</small>
                  </p>
                </div>
              </div>
            </div>

            <InvitedUsers />

            <div className="col-12">
              <div className="card overflow-hidden">
                <div className="card-header d-block d-sm-flex border-0">
                  <div>
                    <h4 className="fs-20 text-black">Referral Program Description</h4>
                    <p className="mb-0 fs-12">
                      
                    </p>
                  </div>
                  {/* <div className="card-action card-tabs mt-3 mt-sm-0">
                    <button className="btn btn-sm btn-success text-white" onClick={() => setBasicModal(true)}>Top Up balance</button>
                  </div> */}
                </div>
                <div className="card-body pt-0 text-black">
                  <p>Welcome to our Referral Program, where you can earn rewards simply by inviting friends and colleagues to join our platform! Here's how it works:</p>
                  <p><b>1. Invite Friends:</b><br/>Share your unique referral link with your network. You can find your referral link in your account dashboard.</p>
                  <p><b>2. Earn Rewards:</b><br/>For every person who signs up using your referral link and makes a payment, you will receive 10% of their payment as a reward.</p>
                </div>
              </div>

            </div>

            <div className="col-12">
              <div className="card overflow-hidden">
                <div className="card-header d-block d-sm-flex border-0">
                  <div>
                    <h4 className="fs-20 text-black">Reward Structure</h4>
                    <p className="mb-0 fs-12">
                      
                    </p>
                  </div>
                  {/* <div className="card-action card-tabs mt-3 mt-sm-0">
                    <button className="btn btn-sm btn-success text-white" onClick={() => setBasicModal(true)}>Top Up balance</button>
                  </div> */}
                </div>
                <div className="card-body pt-0 text-black">
                  <p><b>1. Lifetime License Purchases:</b></p>
                  <p>When an invited user purchases a Lifetime License, you earn 10% of the purchase price.</p>
                  <p>Example: If the Lifetime License costs $399, you will receive $39.90 for each license sold.</p>

                  <p><b>2. Monthly Profit-Based Licenses:</b></p>
                  <p>If an invited user opts for a license based on a percentage of monthly profit, you will receive 10% of the amount they pay in invoices for one year.</p>
                  <p>Example: If your invited user pays $100 in a month, you will earn $10 for that month. This continues for up to 12 months, as long as the user continues to pay.</p>
                </div>
              </div>

            </div>

          </div>
        </>
      )}
    </>
  );
};

export default ExchangesConnect;
