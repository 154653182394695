import React, { Fragment, useState, useContext } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import ChatBox from "../ChatBox";
import { ThemeContext } from "../../../context/ThemeContext";

const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);

  const [toggleMenu, setToggleMenu] = useState(false);
  const { navigationHader, openMenuToggle, background, setMenuToggle, sideBarStyle } = useContext(
    ThemeContext
  );

  return (
    <Fragment>
      <NavHader toggle={toggleMenu} setToggle={setToggleMenu} />
      <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} />
      <Header
        onNote={() => onClick("chatbox")}
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onBox={() => onClick("box")}
        onClick={() => ClickToAddEvent()}
      />
      <SideBar toggle={toggleMenu} setToggle={setToggleMenu} openMenuToggle={openMenuToggle} />
    </Fragment>
  );
};

export default JobieNav;
