import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
// image
import logo from "../../images/logo/logo_full.png";
import loadingGif from "../../images/loading.gif";

import axios from 'axios';
import { baseURL } from "../../baseURL";
import Cookies from 'js-cookie';

const Register = ({ history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [disabledButton, setDisabledButton] = useState(true);
  const [confirmCheckbox, setConfirmCheckbox] = useState(false);
  const [loading, setLoading] = useState(false);
  
  function isValidEmail(email) {
    // Regular expression for email validation
    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  function changeConfirmChackbox(value) {
    setConfirmCheckbox(value);
    if(value === true) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setDisabledButton(true);
    setLoading(true);

    var ref = "";
    if(Cookies.get('ref') !== undefined && Cookies.get('ref') !== null) {
      ref = Cookies.get('ref');
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      ref = urlParams.get('r');
      if(ref != null) {
        Cookies.set('ref', ref, { expires: 30, path: '/'});
      }
    }
    if(ref == null) {
      ref = "";
    }

    if (!isValidEmail(email)) {
      setError("Email is not valid.")
      setDisabledButton(false);
      setLoading(false);
      return "";
    }

    if(password.length === 0) {
      setError("The Password field is blank.")
      setDisabledButton(false);
      setLoading(false);
      return "";
    }
    if(confirmPassword.length === 0) {
      setError("The Confirm Password field is blank.")
      setDisabledButton(false);
      setLoading(false);
      return "";
    }
    if(password !== confirmPassword) {
      setError("The Password and Confirm Password do not match.")
      setDisabledButton(false);
      setLoading(false);
      return "";
    }

    const data = {
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      ref: ref
    }

    try {
      const response = await axios.post(baseURL+'/api/user/register', data);
      if(response.data.status === 'ok') {
        Cookies.set('token', response.data.token, { expires: 1, path: '/'});
        Cookies.set('email', response.data.email, { expires: 1, path: '/'});
        history.push("/page-confirm-email");
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    
    setDisabledButton(false);
    setLoading(false);
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img alt="" src={logo} width="120" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Sign up your account</h4>

                    {error !== "" && (
                      <Alert variant="danger" className="alert-dismissible fade show">
                        {error}
                      </Alert>
                    )}
                    
                    <form onSubmit={(e) => submitHandler(e)}>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="hello@example.com"
                          onChange={(e) => {setEmail(e.target.value); setError('')} }
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          onChange={(e) => {setPassword(e.target.value); setError('')}}
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Confirm Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Confirm Password"
                          onChange={(e) => {setConfirmPassword(e.target.value); setError('')}}
                        />
                      </div>
                      <div className="form-group">
                          <div className="custom-control custom-checkbox ml-1 ">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="basic_checkbox_1"
                              checked={confirmCheckbox}
                              onChange={(e) => changeConfirmChackbox(e.target.checked)}
                            />
                            <label className="custom-control-label" htmlFor="basic_checkbox_1">
                              <small>I certify that I am 18 years of age or older, I agree to the <Link to="/page-terms" className="text-info">Terms of service</Link>, and I have read the <Link to="/page-privacy-policy" className="text-info">Privacy Policy.</Link></small>
                            </label>
                          </div>
                        </div>
                      <div className="text-center mt-4">
                        <button type="submit" className="btn btn-primary btn-block justify-content-center align-items-center h-100" disabled={disabledButton}>
                          {loading === false && (
                            <span>Sign up</span>
                          )}
                          {loading === true && (
                            <span><img alt="" src={loadingGif} width="18" /></span>
                          )}
                          {/* <span>Sign up</span> */}
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="">
                        Already have an account?{" "}
                        <Link className="text-primary" to="/page-login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
