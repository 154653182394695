import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

const LifetimeAlert = () => {
  const [licenseActive, setLicenseActive] = useState(1);
  const [freeTrialActive, setFreeTrialActive] = useState(0);
  const [freeTrialEndDate, setFreeTrialEndDate] = useState('');

  useEffect(() => {
    getLicenseInfo();
  }, []);

  async function getLicenseInfo() {
    const data = {
      token: Cookies.get('token')
    }

    try {
      const response = await axios.post(baseURL+'/api/lifetime_license/get_user_info', data);
      console.log(response.data);
      if(response.data.status === 'ok') {
        setLicenseActive(response.data.lifetime_license);
        setFreeTrialActive(response.data.free_trial_active);

        setFreeTrialEndDate(convertTimestampToYmd(response.data.free_trial_end_timestamp));

        
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }

    // setLoadingOpenPositions(false);
  }

  function convertTimestampToYmd(timestamp) {
    // // Create a new Date object from the timestamp
    // const date = new Date(timestamp * 1000);

    // // Extract the date components
    // const year = date.getFullYear();
    // const month = ('0' + (date.getMonth() + 1)).slice(-2);
    // const day = ('0' + date.getDate()).slice(-2);
    // const hours = ('0' + date.getHours()).slice(-2);
    // const minutes = ('0' + date.getMinutes()).slice(-2);
    // const seconds = ('0' + date.getSeconds()).slice(-2);

    // // Construct the formatted date string
    // const formattedDate = year + '.' + month + '.' + day;

    // Convert timestamp to Date object
    let date = new Date(timestamp * 1000);

    // Define options for formatting the date
    let options = { 
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        timeZone: 'UTC' 
    };

    // Format the date
    let formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }


  return (
    <div className="col-12">
      {licenseActive === 0 && freeTrialActive == 0 && (
        <Alert variant="info" className="alert-dismissible fade show">
          <b>Your account is not activated.</b> Activate a Lifetime license and get unlimited access to BETTTR Trade.<br/>
          <Link to="./license" style={{textDecoration: 'underline'}}>Open the "License" section.</Link>
        </Alert>
      )}

      {licenseActive === 0 && freeTrialActive == 1 && (
        <Alert variant="info" className="alert-dismissible fade show">
          Free trial period activated until {freeTrialEndDate}.
        </Alert>
      )}
    </div>
  );
};

export default LifetimeAlert;
