import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
// image
import logo from "../../images/logo/logo_full.png";

import axios from 'axios';
import { baseURL } from "../../baseURL";

const Register = () => {

  async function checkEmailVerified() {
    // try {
      const queryParameters = new URLSearchParams(window.location.search);
      const emailToken = queryParameters.get("token");
      const email = queryParameters.get("email");
      const data = {
          emailToken: emailToken,
          email: email,
      }
      const response = await axios.post(baseURL+'/api/user/confirm-email', data)
      if(response.data.status === 'ok') {
          window.location.href = '/page-confirm-email?confirm-email-status=success'
      } else {
          window.location.href = '/page-confirm-email?confirm-email-status=failed'
      }
    // } catch(error) {
    //   await checkEmailVerified();
    // }
  }

  useEffect(() => {
      checkEmailVerified();
  }, [])

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center">
                      <Link to="/">
                        <img src={logo} width="120" alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Confirm your email</h4>

                    <p className="text-center">
                      <Badge variant="info light">Loading...</Badge>
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
