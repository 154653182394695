import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { Alert } from "react-bootstrap";
import {SetBackgroundTheme} from "../../setBackgroundTheme";
import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

const ExchangesConnect = ({ history }) => {
  const [exchangePlatform, setExchangePlatform] = useState('binance');
  const [internalName, setInternalName] = useState('');
  const [apiKey, setAPIKey] = useState('');
  const [apiSecret, setAPISecret] = useState('');
  const [tradingCapital, setTradingCapital] = useState(100);
  const [positionSize, setPositionSize] = useState(1);
  const [positionSizeType, setPositionSizeType] = useState('percent');
  const [reinvestProfit, setReinvestProfit] = useState(1);

  const [disabledButton, setDisabledButton] = useState(false);
  const [error, setError] = useState('');
  

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getLicenseInfo();
    SetBackgroundTheme(changeBackground, Cookies);
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => changePositionSize(positionSize), 1000);
    return () => clearTimeout(timeOutId);
  }, [positionSize]);

  async function getLicenseInfo() {
    const data = {
      token: Cookies.get('token')
    }

    try {
      const response = await axios.post(baseURL+'/api/lifetime_license/get_user_info', data);
      console.log(response.data);
      if(response.data.status === 'ok') {
        if(response.data.lifetime_license === 0 && response.data.free_trial_active === 0) {
          history.push("/exchanges");
        }
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }

    // setLoadingOpenPositions(false);
  }

  function changePositionSize(values, position_size_type) {
    // const values = parseFloat(e.target.value);
    if(values < 0) {
      setPositionSize(1);
      return "";
    }

    if(positionSizeType === 'usdt' || position_size_type === 'usdt') {
      if(values < 8) {
        setPositionSize(8);
        return "";
      }

      if(values > tradingCapital) {
        setPositionSize(tradingCapital);
        return "";
      }
    }

    if(positionSizeType === 'percent' || position_size_type === 'percent') {
      if(values > 100) {
        setPositionSize(100);
        return "";
      }

      if(values <= 0) {
        setPositionSize(1);
        return "";
      }
    }

    setPositionSize(values);
  }

  function changePositionSizeType(value) {
    setPositionSizeType(value);
    changePositionSize(positionSize, value);
  }

  async function connectExchange() {

    if(positionSizeType === 'usdt' && positionSize < 8) {
      setError("The minimum position size is 8 USDT.");
      return "";
    }

    setDisabledButton(true);
    const data = {
      token: Cookies.get('token'),
      name: internalName,
      platform: exchangePlatform,
      key: apiKey,
      secret: apiSecret,
      invested_max: tradingCapital,
      positions_number_max: 10000,
      positions_number_same_pair_max: 1,
      position_size: positionSize,
      position_size_type: positionSizeType,
      reinvest_profits: reinvestProfit
    }

    try {
      const response = await axios.post(baseURL+'/api/exchange/connect', data);
      if(response.data.status === 'ok') {
        // setExchanges(response.data.exchanges);
        history.push("/exchanges");
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    setDisabledButton(false);
  }




  // // Active pagginarion
  // activePag.current === 0 && chageData(0, sort);
  // // paggination
  // let paggination = Array(Math.ceil(data.length / sort))
  //   .fill()
  //   .map((_, i) => i + 1);

  // // Active paggination & chage data
  // const onClick = (i) => {
  //   activePag.current = i;
  //   chageData(activePag.current * sort, (activePag.current + 1) * sort);
  //   settest(i);
  // };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Connect new exchange</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>

                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Exchange</label>
                    <div className="col-sm-9">
                      <select className="form-control" onChange={(e) => setExchangePlatform(e.target.value)}>
                        <option value={'binance'}>Binance</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Internam name</label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        placeholder=""
                        onChange={(e) => setInternalName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">API Key</label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        placeholder=""
                        onChange={(e) => setAPIKey(e.target.value)}
                      />
                    </div>
                  </div>
                  
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">API Secret</label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        placeholder=""
                        onChange={(e) => setAPISecret(e.target.value)}
                      />
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Trading Capital, USDT</label>
                    <div className="col-sm-9">
                      <input
                        type="number"
                        className="form-control"
                        placeholder=""
                        value={tradingCapital}
                        onChange={(e) => setTradingCapital(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Position Size</label>
                    <div className="col-sm-9">
                      <div className="row">
                        <div className="col-6">
                          <input
                            type="number"
                            className="form-control"
                            placeholder=""
                            min={0}
                            value={positionSize}
                            onChange={(e) => setPositionSize(e.target.value)}
                          />
                        </div>
                        <div className="col-6">
                          <select className="form-control" onChange={(e) => changePositionSizeType(e.target.value)}>
                            <option value={'usdt'}>USDT</option>
                            <option value={'percent'}>%</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Reinvest Profits</label>
                    <div className="col-sm-9">
                      <select className="form-control" onChange={(e) => setReinvestProfit(e.target.value)}>
                        <option value={1} selected={reinvestProfit === 1 ? true : false}>Yes</option>
                        <option value={0} selected={reinvestProfit === 0 ? true : false}>No</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label"></label>
                    <div className="col-sm-9">
                      <button className="btn btn-md btn-info" disabled={disabledButton} onClick={() => connectExchange()}>
                        Connect
                      </button>

                      {error !== "" && (
                        <Alert variant="danger" className="mt-3 alert-dismissible fade show">
                          {error}
                        </Alert>
                      )}

                    </div>
                  </div>

                  {/* <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-md btn-info">
                        Connect
                      </button>
                    </div>
                  </div> */}

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExchangesConnect;
