import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { Badge, Dropdown, OverlayTrigger, Popover, Button, Alert } from "react-bootstrap";
import {SetBackgroundTheme} from "../../setBackgroundTheme";
import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

import Connect from './Connect.js'
import Trading from './Trading.js'

const Exchanges = () => {
  const [loading, setLoading] = useState(true);
  const [accountConnected, setAccountConnected] = useState(false);
  const [accountDetails, setAccountDetails] = useState({});

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getAccountConnected();
    SetBackgroundTheme(changeBackground, Cookies);
  }, []);

  async function getAccountConnected() {
    setLoading(true);

    const data = {
      token: Cookies.get('token')
    }

    try {
      const response = await axios.post(baseURL+'/api/demo_exchange/get_account_connected', data);
      console.log(response.data);
      if(response.data.status === 'ok') {
        setAccountConnected(response.data.account_connected);
        setAccountDetails(response.data.account_details);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }
    setLoading(false);
  }

  return (
    <>

      {loading === true && (
        <Badge variant="info light">Loading...</Badge>
      )}

      {loading === false && accountConnected === false && (
        <Connect setAccountConnected={setAccountConnected} />
      )}

      {loading === false && accountConnected === true && (
        <Trading accountDetails={accountDetails} />
      )}

    </>
  );
};

export default Exchanges;
