import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

// image
import logo from "../../images/logo/logo_full.png";
import loadingGif from "../../images/loading.gif";

import axios from 'axios';
import { baseURL } from "../../baseURL";
import Cookies from 'js-cookie';

const Login = ({ history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const token = urlParams.get('token');

    checkUserAuth(token, email);


  }, []);

  async function checkUserAuth(token, email) {
    const data = { token: token }
    const response = await axios.post(baseURL+'/api/user/auth_check', data)
    if(response.data.status === 'ok') {
      Cookies.set('token', response.data.token, { expires: 1, path: '/'});
      Cookies.set('email', response.data.email, { expires: 2, path: '/'});
      window.location.href = '/';
    } else {
      window.location.href = '/page-login';
    }
  }

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={logo} width="120" alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Loading...</h4>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
