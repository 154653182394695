// TradingViewChart.js
import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const TradingViewChart = ({ buyPoints, sellPoints, chartData, priceMin, priceRoundNumber, theme }) => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null); // Use this ref to keep track of the chart instance

  const themeColors = {
    light: {
      backgroundColor: '#fff',
      textColor: '#333',
      vertLinesColor: '#e1e9f0',
      horzLinesColor: '#e1e9f0',
      timeScaleborderColor: '#ccc',
    },
    dark: {
      backgroundColor: '#1e1e1e',
      textColor: '#ffffff',
      vertLinesColor: '#2b2b2b',
      horzLinesColor: '#2b2b2b',
      timeScaleborderColor: '#444',
    },
  }

  useEffect(() => {
    if (chartContainerRef.current && !chartRef.current) {
      // Create the chart
      const chart = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.clientWidth,
        height: chartContainerRef.current.clientHeight,
        layout: {
          background: theme === 'light' ? null : themeColors.dark.backgroundColor,
          backgroundColor: theme === 'light' ? themeColors.light.backgroundColor : themeColors.dark.backgroundColor,
          textColor: theme === 'light' ? themeColors.light.textColor : themeColors.dark.textColor,
        },
        grid: {
          vertLines: {
            color: theme === 'light' ? themeColors.light.vertLinesColor : themeColors.dark.vertLinesColor,
          },
          horzLines: {
            color: theme === 'light' ? themeColors.light.horzLinesColor : themeColors.dark.horzLinesColor,
          },
        },
        crossHair: {
          mode: 0,
        },
        timeScale: {
          borderColor: theme === 'light' ? themeColors.light.timeScaleborderColor : themeColors.dark.timeScaleborderColor,
          timeVisible: true, // Ensure time is visible
          format: {
            dateFormat: 'yyyy-MM-dd HH:mm' // Note: This does not work in Lightweight Charts, you may need to format manually.
          },
        },
        rightPriceScale: {
          autoScale: true,
          visible: true,
        }
      });
      chartRef.current = chart; // Store the chart instance in the ref

      // Create a candlestick series
      const candleSeries = chart.addCandlestickSeries({
        upColor: 'green',
        borderUpColor: 'green',
        wickUpColor: 'green',
        borderDownColor: 'red',
        wickDownColor: 'red',
        color: 'red',
      });

      candleSeries.setData(chartData);
      candleSeries.applyOptions({
        priceFormat: {
            type: 'price',
            precision: priceRoundNumber,
            // minMove: priceMin,
        },
      });

      // Convert points to markers
      const markers = [
        ...buyPoints.map(point => ({
          // time: new Date(point.time).toISOString().split('T')[0],
          time: point.time,
          position: 'belowBar',
          color: 'green',
          shape: 'circle',
          text: 'Buy',
        })),
        ...sellPoints.map(point => ({
          // time: new Date(point.time).toISOString().split('T')[0],
          time: point.time,
          position: 'aboveBar',
          color: 'red',
          shape: 'circle',
          text: 'Sell',
        })),
      ];

      // Add markers to the series
      candleSeries.setMarkers(markers);

      // Handle resizing
      const handleResize = () => {
        chart.resize(chartContainerRef.current.clientWidth, chartContainerRef.current.clientHeight);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
        // Clean up chart instance
        if (chartRef.current) {
          chartRef.current.remove();
          chartRef.current = null;
        }
      };
    }
  }, [buyPoints, sellPoints]);

  return (
    <div
      ref={chartContainerRef}
      style={{ position: 'relative', width: '100%', height: '300px' }}
    />
  );
};

export default TradingViewChart;
