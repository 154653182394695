import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { Badge, Dropdown, OverlayTrigger, Popover, Button, Alert } from "react-bootstrap";
import {SetBackgroundTheme} from "../../setBackgroundTheme";
import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

const Exchanges = () => {
  const [exchanges, setExchanges] = useState([]);
  const [, setUpdateIntervalID] = useState(null);
  const [loading, setLoading] = useState(true);

  const [licenseType, setLicenseType] = useState('');
  const [licenseActive, setLicenseActive] = useState('');
  const [freeTrialActive, setFreeTrialActive] = useState(0);
  
  const { changeBackground } = useContext(ThemeContext);
  useEffect(async () => {
    await getLicenseInfo();

    const reload_exchanges = await getExchanges();
    if(reload_exchanges === 'yes') {
      // Set interval to run the function every minute
      const intervalId = setInterval(getExchanges, 10000); // 60000 milliseconds = 1 minute
      setUpdateIntervalID(intervalId);
      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }

    SetBackgroundTheme(changeBackground, Cookies);
    
  }, []);

  async function getLicenseInfo() {
    const data = {
      token: Cookies.get('token')
    }

    try {
      const response = await axios.post(baseURL+'/api/lifetime_license/get_user_info', data);
      console.log(response.data);
      if(response.data.status === 'ok') {
        setLicenseType(response.data.license_type);
        setLicenseActive(response.data.lifetime_license);
        setFreeTrialActive(response.data.free_trial_active);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }

    // setLoadingOpenPositions(false);
  }

  async function getExchanges() {
    var reload_exchanges = "no";
    const data = {
      token: Cookies.get('token')
    }

    try {
      const response = await axios.post(baseURL+'/api/exchange/get_all', data);
      if(response.data.status === 'ok') {
        setExchanges(response.data.exchanges);

        for(const e of response.data.exchanges) {
          if(e.status === 'checking') {
            reload_exchanges = "yes";
          }
        }

      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }

    setLoading(false);

    return reload_exchanges;
  }

  return (
    <>

      <div className="row">
        <div className="col-12">
          <div className="card overflow-hidden">
            <div className="card-header d-block d-sm-flex border-0">
              <div>
                <h4 className="fs-20 text-black">List of your connected exchanges</h4>
                <p className="mb-0 fs-12">
                  
                </p>
              </div>
              <div className=" mt-3 mt-sm-0">
                {(licenseType === 'percent' || licenseActive === 1 || freeTrialActive == 1) && (
                  <Link to="/exchanges/connect" className="btn btn-sm btn-info">Connect Exchange</Link>
                )}
                {(licenseType === 'lifetime' && licenseActive === 0 && freeTrialActive == 0) && (
                  <Link to="/license" className="btn btn-sm btn-info">Activate Lifetime License</Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading === true && (
        <Badge variant="info light">Loading...</Badge>
      )}

      {loading === false && exchanges.length === 0 && (
        <Alert variant="primary" className="alert-dismissible fade show">
          There are no connected exchanges.
        </Alert>
      )}

      {loading === false && exchanges.length > 0 && (
        <div className="row">
          <div className="col-xl-12">
            <div className="table-responsive table-hover fs-14 ">
              <div id="example6_wrapper" className="dataTables_wrapper no-footer">
                <table
                  className="table display mb-4 dataTablesCard font-w600 short-one border-no card-table text-black dataTable no-footer"
                  id="marketCapital"
                  role="grid"
                  aria-describedby="example6_info"
                >
                  <thead>
                    <tr role="row">
                      <th
                        className="sorting_asc"
                        // tabIndex={0}
                        // aria-controls="example6"
                        // rowSpan={1}
                        // colSpan={1}
                        // aria-sort="ascending"
                        // aria-label="Rank: activate to sort column descending"
                        // style={{ width: 63 }}
                      >
                        <small>Name</small>
                      </th>
                      <th
                        className="sorting_asc"
                        // tabIndex={0}
                        // aria-controls="example6"
                        // rowSpan={1}
                        // colSpan={1}
                        // aria-sort="ascending"
                        // aria-label="Rank: activate to sort column descending"
                        // style={{ width: 63 }}
                      >
                        <small>Exchange</small>
                      </th>
                      <th
                        className="sorting_asc"
                        // tabIndex={0}
                        // aria-controls="example6"
                        // rowSpan={1}
                        // colSpan={1}
                        // aria-sort="ascending"
                        // aria-label="Rank: activate to sort column descending"
                        // style={{ width: 63 }}
                      >
                        <small>Trading Capital</small>
                      </th>
                      {/* <th
                        // className="sorting"
                        // tabIndex={0}
                        // aria-controls="example6"
                        // rowSpan={1}
                        // colSpan={1}
                        // aria-label="Last Price: activate to sort column ascending"
                        // style={{ width: "76.6667px" }}
                      >
                        <small>Position size</small>
                      </th> */}
                      <th
                        // className="sorting"
                        // tabIndex={0}
                        // aria-controls="example6"
                        // rowSpan={1}
                        // colSpan={1}
                        // aria-label="Change (24h): activate to sort column ascending"
                        // style={{ width: 104 }}
                      >
                        <small>Reinvest profits</small>
                      </th>
                      <th
                        // className="sorting"
                        // tabIndex={0}
                        // aria-controls="example6"
                        // rowSpan={1}
                        // colSpan={1}
                        // aria-label="Change (24h): activate to sort column ascending"
                        // style={{ width: 104 }}
                      >
                        <small>Status</small>
                      </th>
                      <th
                        // className="sorting"
                        // tabIndex={0}
                        // aria-controls="example6"
                        // rowSpan={1}
                        // colSpan={1}
                        // aria-label="Change (24h): activate to sort column ascending"
                        // style={{ width: 104 }}
                      >
                        <small>Action</small>
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                    {exchanges &&
                      exchanges.map((item, key) => (
                        <tr key={key} role="row" className="odd">
                          <td className="wspace-no sorting_1">
                            {item.name}
                          </td>
                          <td className="wspace-no sorting_1">
                            {item.platform}
                          </td>
                          <td className="wspace-no sorting_1">
                            {item.invested_max} USDT
                          </td>
                          {/* <td className="wspace-no">
                            {item.position_size_type === 'usdt' && (
                              <span>{item.position_size} USDT</span>
                            )}
                            {item.position_size_type === 'percent' && (
                              <span>{item.position_size}% trading capital</span>
                            )}
                          </td> */}
                          <td>
                            {item.reinvest_profits === 1 && (
                              <Badge variant="success light">Yes</Badge>
                            )}
                            {item.reinvest_profits === 0 && (
                              <Badge variant="warning light">No</Badge>
                            )}
                          </td>
                          <td>
                            {item.status === "connected" && (
                              <Badge variant="success light">Connected</Badge>
                            )}
                            {item.status === "checking" && (
                              <Badge variant="warning light">Checking</Badge>
                            )}
                            {item.status === "error" && (
                              // <Badge variant="danger light">Error</Badge>
                              <OverlayTrigger
                                trigger="click"
                                placement="top"
                                responsive={true}
                                className=""
                                overlay={
                                  <Popover
                                    id={`popover-positioned-top`}
                                  >
                                    <Popover.Content>
                                      {item.status_comment}
                                    </Popover.Content>
                                  </Popover>
                                }
                              >
                                <Button variant="danger" size="xs" className="">
                                  Error
                                </Button>
                              </OverlayTrigger>
                            )}
                            {item.status === "disconnected" && (
                              <Badge variant="info light">Disconnected</Badge>
                            )}
                          </td>
                          <td>
                          <Dropdown className="dropdown ml-4">
                              <Dropdown.Toggle
                                variant=""
                                className="i-false p-0 btn-link"
                                data-toggle="dropdown"
                              >
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                    stroke="#A7A7A7"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                                    stroke="#A7A7A7"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                                    stroke="#A7A7A7"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                className="dropdown-menu dropdown-menu-right"
                                alignRight={true}
                              >
                                <Link
                                  className="dropdown-item"
                                  to={`/exchanges/edit?id=${item.id}`}
                                >
                                  Edit
                                </Link>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      )
                    )}

                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Exchanges;
