import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { Badge, Dropdown, OverlayTrigger, Popover, Button, Alert } from "react-bootstrap";
import {SetBackgroundTheme} from "../../setBackgroundTheme";
import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

const Connect = ({ history, setAccountConnected }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [tradingCapital, setTradingCapital] = useState(10000);

  const [disabledButton, setDisabledButton] = useState(false);
  const [error, setError] = useState('');
  

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    SetBackgroundTheme(changeBackground, Cookies);
  }, []);

  async function connectExchange() {
    setDisabledButton(true);

    const data = {
      token: Cookies.get('token'),
      first_name: firstName,
      last_name: lastName,
      trading_capital: tradingCapital
    }

    try {
      const response = await axios.post(baseURL+'/api/demo_exchange/connect', data);
      if(response.data.status === 'ok') {
        setAccountConnected(true);
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    setDisabledButton(false);
  }

  return (
    <>

      <Alert variant="primary" className="alert-dismissible fade show">
        <b>Try now with a free demo account!</b> It will fully emulate real trading on BETTR Trade.
      </Alert>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Connect New Demo Account</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">First Name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Last Name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Demo Trading Capital, USDT</label>
                    <div className="col-sm-9">
                      <select className="form-control" onChange={(e) => setTradingCapital(e.target.value)}>
                        <option value={10000}>10 000</option>
                        <option value={50000}>50 000</option>
                        <option value={100000}>100 000</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label"></label>
                    <div className="col-sm-9">
                      <button className="btn btn-md btn-info" disabled={disabledButton} onClick={() => connectExchange()}>
                        Connect
                      </button>

                      {error !== "" && (
                        <Alert variant="danger" className="mt-3 alert-dismissible fade show">
                          {error}
                        </Alert>
                      )}

                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Connect;
