import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import {
  Table,
  Badge,
  Pagination,
  Alert
} from "react-bootstrap";
import pattern1 from "../../../images/pattern/pattern1.png";
import pattern2 from "../../../images/pattern/pattern2.png";

import axios from 'axios';
import { baseURL, basePromoURL } from "../../../baseURL";
import Cookies from 'js-cookie';

import {SetBackgroundTheme} from "../../setBackgroundTheme";

const InvitedUsers = () => {
  const [commissionRate, setCommissionRate] = useState(0);
  const [invitedUsers, setInvitedUsers] = useState(0);
  const [invitedActiveUsers, setInvitedActiveUsers] = useState(0);
  const [referralBalance, setReferralBalance] = useState(0);
  const [level, setLevel] = useState(0);
  const [referralUsername, setReferralUsername] = useState('');
  const [newReferralUsername, setNewReferralUsername] = useState('');
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [referralUsernameUpdateLoading, setReferralUsernameUpdateLoading] = useState(false);
  const [referralUsernameError, setReferralUsernameError] = useState('');
  const [users, setUsers] = useState([]);

  const [active, setActive] = useState(1);
  const [maxPage, setMaxPage] = useState(2);
  const [pageItems, setPageItems] = useState([{p:1}]);
  const [loadingBadge, setLoadingBadge] = useState(false);
  const [maxAvailablePage, setMaxAvailablePage] = useState(1);
  
  

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getReferralInfo();
    getInvitedUsers(1);

    SetBackgroundTheme(changeBackground, Cookies);

    // eslint-disable-next-line
  }, []);

  function setNavigationLinks(max_page) {
    var page_items_tmp = [];
    for (let number = active; number <= max_page; number++) {
      page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
  }

  function changePage(page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    var max_page = maxPage;
    if(page_number > 5) {
      number_first = page_number - 5;
      max_page = max_page + number_first;
      if(max_page > maxAvailablePage){
        max_page = maxAvailablePage;
      }
    }

    var page_items_tmp = [];
    for (let number = number_first; number <= max_page; number++) {
        page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
    getInvitedUsers(page_number);
  }

  function changePageNoReload(page, max_page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    if(page_number > 5) {
        number_first = page_number - 5;
        max_page = max_page + number_first;
    }

    var page_items_tmp = [];
    for (let number = number_first; number <= max_page; number++) {
        page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
  }

  function changePagePrev() {
    var next_page = active - 1;
    if(next_page < 1) {
      next_page = 1;
    }
    setActive(next_page);
    changePage(next_page);
  }

  function changePageNext() {
    const next_page = active + 1;
    if(next_page <= maxAvailablePage) {
      setActive(next_page);
      changePage(next_page);
    }
  }

  async function getInvitedUsers(page_number) {
    const data = {
      token: Cookies.get('token'),
      page: page_number
    }

    try {
      const response = await axios.post(baseURL+'/api/referral/get_invited_users', data);
      if(response.data.status === 'ok') {
        setUsers(response.data.invited_users);

        setNavigationLinks(response.data.max_page);
        setMaxAvailablePage(response.data.max_page);
        setMaxPage(response.data.max_page);
        changePageNoReload(page_number, response.data.max_page);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }

    setLoadingBadge(false);
  }

  async function getReferralInfo() {
    const data = {
      token: Cookies.get('token')
    }

    try {
      const response = await axios.post(baseURL+'/api/referral/get_info', data);
      if(response.data.status === 'ok') {
        setCommissionRate(response.data.commission_rate);
        setLevel(response.data.level);
        setReferralUsername(response.data.referral_username);
        setNewReferralUsername(response.data.referral_username);
        setInvitedUsers(response.data.invited_users);
        setInvitedActiveUsers(response.data.invited_active_users);
        setReferralBalance(response.data.referral_balance);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }

    // setLoadingOpenPositions(false);
  }

  async function copyToClipboard() {
    const referral_link = basePromoURL + "/?r=" + referralUsername
    try {
      await navigator.clipboard.writeText(referral_link);
      setCopiedToClipboard(true);
      await delay(3000);
      setCopiedToClipboard(false);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  function changeReferralUsername(value) {
    setReferralUsernameError('');
    var value_new = value.replace(/[^a-zA-Z]/g, '');
    if(value_new.length > 20) {
      value_new = value_new.slice(0, 20);
    }
    value_new = value_new.toLowerCase();
    setNewReferralUsername(value_new);
  }

  async function updateReferralUsername() {
    setReferralUsernameError('');
    setReferralUsernameUpdateLoading(true);
    const data = {
      token: Cookies.get('token'),
      referral_username: newReferralUsername
    }

    try {
      const response = await axios.post(baseURL+'/api/referral/update_referral_username', data);
      if(response.data.status === 'ok') {
        await getReferralInfo();
      } else {
        setReferralUsernameError(response.data.text);
      }
    } catch(err) {

    }

    setReferralUsernameUpdateLoading(false);
  }

  function convertTimestampToYmd(timestamp) {
    // // Create a new Date object from the timestamp
    // const date = new Date(timestamp * 1000);

    // // Extract the date components
    // const year = date.getFullYear();
    // const month = ('0' + (date.getMonth() + 1)).slice(-2);
    // const day = ('0' + date.getDate()).slice(-2);
    // const hours = ('0' + date.getHours()).slice(-2);
    // const minutes = ('0' + date.getMinutes()).slice(-2);
    // const seconds = ('0' + date.getSeconds()).slice(-2);

    // // Construct the formatted date string
    // const formattedDate = year + '.' + month + '.' + day;

    // Convert timestamp to Date object
    let date = new Date(timestamp * 1000);

    // Define options for formatting the date
    let options = { 
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        timeZone: 'UTC' 
    };

    // Format the date
    let formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  return (
    <>
      <div className="col-12">
        <div className="card overflow-hidden">
          <div className="card-header d-block d-sm-flex border-0">
            <div>
              <h4 className="fs-20 text-black">Invited Users</h4>
              <p className="mb-0 fs-12">
                
              </p>
            </div>
            {/* <div className="card-action card-tabs mt-3 mt-sm-0">
              <button className="btn btn-sm btn-success text-white" onClick={() => setBasicModal(true)}>Top Up balance</button>
            </div> */}
          </div>
          <div className="card-body pt-0">

            {loadingBadge === false && users.length === 0 && (
              <Alert variant="primary" className="alert-dismissible fade show">
                There are no invited users.
              </Alert>
            )}

            {users.length > 0 && (
              <Table responsive className="w-100">
                <div id="example_wrapper" className="dataTables_wrapper">
                    <table
                      id="example"
                      className="display w-100 dataTable"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                          <th>Date</th>
                          <th>Username</th>
                          <th>Active</th>
                      </thead>
                      <tbody>
                          
                          {users &&
                            users.map((item, key) => (
                              <tr key={key}>
                                <td role="row" className="wspace-no">{convertTimestampToYmd(item.created_at)}</td>
                                <td className="wspace-no">{item.referral_username}</td>
                                <td>
                                  {item.active === 0 && (
                                    <Badge variant="warning light">Inactive</Badge>
                                  )}
                                  {item.active === 1 && (
                                    <Badge variant="success light">Active</Badge>
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                          
                      </tbody>
                      {/* <tfoot>
                          <tr role="row">
                            {data.jobsTable.columns.map((d, i) => (
                                <th key={i}>{d}</th>
                            ))}
                          </tr>
                      </tfoot> */}
                    </table>
                </div>
              </Table>
            )}

            {users.length > 0 && (
              <div className="">
                <Pagination
                  size="sm"
                  className={`mt-4 pagination-gutter pagination-primary no-bg pagination-circle`}
                >
                  <li className="page-item page-indicator">
                    <Link className="page-link" to="#" onClick={() => changePagePrev()}>
                      <i className="la la-angle-left" />
                    </Link>
                  </li>

                  {pageItems.length > 0 &&
                    pageItems.map((item, i) => (
                      <Pagination.Item key={i} active={item.p === active ? true : false} onClick={() => {changePage(item.p)}}>
                        {item.p}
                      </Pagination.Item>
                    )
                  )}
                  
                  <li className="page-item page-indicator">
                    <Link className="page-link" to="#" onClick={() => changePageNext()}>
                      <i className="la la-angle-right" />
                    </Link>
                  </li>
                </Pagination>

                {loadingBadge === true && (
                  <div className="mt-3">
                    <Badge variant="info light">Loading</Badge>
                  </div>
                )}
                
              </div>
            )}

          </div>
        </div>

      </div>
    </>
  );
};

export default InvitedUsers;
