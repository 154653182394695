import React, { useState, useEffect, useContext } from "react";
import { Dropdown, Badge, Popover, OverlayTrigger, Button, Pagination, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";

import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

import {SetBackgroundTheme} from "../../setBackgroundTheme";

const Positions = () => {
  const [activeName, setActiveName] = useState("Closed Positions");
  const [activaTableName, setActivaTableName] = useState('closed');
  const [closedPositions, setClosedPositions] = useState([]);
  const [openPositions, setOpenPositions] = useState([]);

  const [loadingClosedPositions, setLoadingClosedPositions] = useState(true);
  const [loadingOpenPositions, setLoadingOpenPositions] = useState(true);
  

  const [active, setActive] = useState(1);
  const [maxPage, setMaxPage] = useState(2);
  const [pageItems, setPageItems] = useState([{p:1}]);
  const [loadingBadge, setLoadingBadge] = useState(false);
  const [maxAvailablePage, setMaxAvailablePage] = useState(1);

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getClosedPositions(1);
    getOpenPositions();
    SetBackgroundTheme(changeBackground, Cookies);

    // Set interval to run the function every minute
    const intervalId = setInterval(getOpenPositions, 10000); // 60000 milliseconds = 1 minute
    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  function setNavigationLinks(max_page) {
    var page_items_tmp = [];
    for (let number = active; number <= max_page; number++) {
        page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
  }

  function changePage(page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    var max_page = maxPage;
    if(page_number > 5) {
      number_first = page_number - 5;
      max_page = max_page + number_first;
      if(max_page > maxAvailablePage){
        max_page = maxAvailablePage;
      }
    }

    var page_items_tmp = [];
    for (let number = number_first; number <= max_page; number++) {
        page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
    getClosedPositions(page_number);
  }

  function changePageNoReload(page, max_page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    if(page_number > 5) {
        number_first = page_number - 5;
        max_page = max_page + number_first;
    }

    var page_items_tmp = [];
    for (let number = number_first; number <= max_page; number++) {
        page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
  }

  function changePagePrev() {
    var next_page = active - 1;
    if(next_page < 1) {
      next_page = 1;
    }
    setActive(next_page);
    changePage(next_page);
  }

  function changePageNext() {
    const next_page = active + 1;
    if(next_page <= maxAvailablePage) {
      setActive(next_page);
      changePage(next_page);
    }
  }

  async function getClosedPositions(page_number) {
    setLoadingBadge(true);
    const data = {
      token: Cookies.get('token'),
      page: page_number
    }

    try {
      const response = await axios.post(baseURL+'/api/demo_exchange/positions/get_closed', data);
      if(response.data.status === 'ok') {
        setClosedPositions(response.data.positions);
        setNavigationLinks(response.data.max_page);
        setMaxAvailablePage(response.data.max_page);
        setMaxPage(response.data.max_page);
        changePageNoReload(page_number, response.data.max_page);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }
    setLoadingBadge(false);
    setLoadingClosedPositions(false);
  }

  async function getOpenPositions() {
    const data = {
      token: Cookies.get('token')
    }

    try {
      const response = await axios.post(baseURL+'/api/demo_exchange/positions/get_open', data);
      if(response.data.status === 'ok') {
        setOpenPositions(response.data.positions);
        
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }

    setLoadingOpenPositions(false);
  }

  function convertTimestampToYmdHis(timestamp) {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp * 1000);

    // Extract the date components
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    // Construct the formatted date string
    const formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    return formattedDate;
  }

  return (
    <>

      <div className="d-flex align-items-center flex-wrap mb-3">
        {/* <div className="input-group search-area-2 mb-3 mr-auto d-inline-flex">
          <div className="input-group-append">
            <a href="#" className="input-group-text">
              <i className="flaticon-381-search-2" />
            </a>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Search here"
          />
        </div> */}
        {/* <a
          href="#"
          className="btn btn-primary btn-rounded mb-3 mr-3"
        >
          <i className="las la-download scale5 mr-2" />
          Get Report
        </a>
        <a
          href="#"
          className="btn btn-outline-primary btn-rounded mb-3 mr-3 button-style"
        >
          <i className="las la-calendar scale5 mr-2" />
          Filter Periode
        </a> */}
        <Dropdown className="dropdown bootstrap-select form-control style-1 default-select mb-3">
          <Dropdown.Toggle
            variant=""
            type="button"
            className="btn dropdown-toggle btn-light"
            data-toggle="dropdown"
            role="button"
            title="Latest"
            aria-expanded="false"
          >
            {activeName}
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dropdown-menu"
            role="combobox"
            x-placement="bottom-start"
            style={{
              maxHeight: 174,
              overflow: "hidden",
              minHeight: 0,
              position: "absolute",
              willChange: "transform",
              top: 0,
              left: 0,
              transform: "translate3d(0px, 41px, 0px)",
            }}
          >
            <Dropdown.Item onClick={() => {setActiveName("Closed Positions"); setActivaTableName('closed')}}>
              Closed Positions
            </Dropdown.Item>
            <Dropdown.Item onClick={() => {setActiveName("Open Positions"); setActivaTableName('open')}}>
              Open Positions
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive table-hover fs-14 ">

            

            {activaTableName === 'closed' && (
              <div id="example6_wrapper" className="dataTables_wrapper no-footer">

                {loadingClosedPositions === true && (
                  <Badge variant="info light">Loading...</Badge>
                )}

                {loadingClosedPositions === false && closedPositions.length === 0 && (
                  <Alert variant="primary" className="alert-dismissible fade show">
                    There are no closed positions at this moment.
                  </Alert>
                )}
                {loadingClosedPositions === false && closedPositions.length > 0 && (
                  <table
                    className="table display mb-4 dataTablesCard font-w600 short-one border-no card-table text-black dataTable no-footer"
                    id="marketCapital"
                    role="grid"
                    aria-describedby="example6_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting_asc"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-sort="ascending"
                          aria-label="Rank: activate to sort column descending"
                          // style={{ width: 63 }}
                        >
                          Start Date
                        </th>
                        <th
                          className="sorting_asc"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-sort="ascending"
                          aria-label="Rank: activate to sort column descending"
                          // style={{ width: 63 }}
                        >
                          End Date
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Coin: activate to sort column ascending"
                          // style={{ width: "118.667px" }}
                        >
                          Pair
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Last Price: activate to sort column ascending"
                          // style={{ width: "76.6667px" }}
                        >
                          Invested, USDT
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Change (24h): activate to sort column ascending"
                          // style={{ width: 104 }}
                        >
                          Entry Price
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Volume (24h): activate to sort column ascending"
                          // style={{ width: "113.333px" }}
                        >
                          Exit Price
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Graph: activate to sort column ascending"
                          // style={{ width: "124.667px" }}
                        >
                          Profit
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Graph: activate to sort column ascending"
                          // style={{ width: "124.667px" }}
                        >
                          Profit, %
                        </th>
                        {/* <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Graph: activate to sort column ascending"
                          // style={{ width: "124.667px" }}
                        >
                          Fees
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Graph: activate to sort column ascending"
                          // style={{ width: "124.667px" }}
                        >
                          Net Profit
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Graph: activate to sort column ascending"
                          // style={{ width: "124.667px" }}
                        >
                          Net Profit, %
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      
                      {closedPositions &&
                        closedPositions.map((item, key) => (
                          <tr key={key} role="row" className="odd">
                            <td className="wspace-no sorting_1">
                              {convertTimestampToYmdHis(item.entry_timestamp)}
                            </td>
                            <td className="wspace-no sorting_1">
                              {convertTimestampToYmdHis(item.exit_timestamp)}
                            </td>
                            <td className="wspace-no">
                              {item.pair}
                            </td>
                            <td>{item.position_size_usdt === null ? "" : item.position_size_usdt.toFixed(4)}</td>
                            <td>{item.entry_avg_price}</td>
                            <td>{item.exit_price}</td>
                            <td>{item.profit === null ? "" :  item.profit.toFixed(4)}</td>
                            <td>
                              <p className="mb-0 wspace-no">
                                <i
                                  className={item.profit_percent >= 0 ? "fa fa-caret-up scale5 mr-2 text-success" : "fa fa-caret-down scale5 mr-2 text-danger"}
                                  aria-hidden="true"
                                />
                                {item.profit_percent === null ? "" : item.profit_percent.toFixed(2)}%
                              </p>
                            </td>
                            {/* <td>{item.total_commission_usdt === null ? "" : item.total_commission_usdt.toFixed(10)}</td>
                            <td>{item.net_profit === null ? "" : item.net_profit.toFixed(4)}</td>
                            <td>
                              <p className="mb-0 wspace-no">
                                <i
                                  className={item.net_profit_percent >= 0 ? "fa fa-caret-up scale5 mr-2 text-success" : "fa fa-caret-down scale5 mr-2 text-danger"}
                                  aria-hidden="true"
                                />
                                {item.net_profit_percent === null ? "" : item.net_profit_percent.toFixed(2)}%
                              </p>
                            </td> */}
                          </tr>
                        )
                      )}

                    </tbody>
                  </table>
                )}
              </div>
            )}

            {activaTableName === 'open' && (
              <div id="example6_wrapper" className="dataTables_wrapper no-footer">

                {loadingOpenPositions === true && (
                  <Badge variant="info light">Loading...</Badge>
                )}

                {loadingOpenPositions === false && openPositions.length === 0 && (
                  <Alert variant="primary" className="alert-dismissible fade show">
                    There are no open positions at this moment.
                  </Alert>
                )}

                {loadingOpenPositions === false && openPositions.length > 0 && (
                  <table
                    className="table display mb-4 dataTablesCard font-w600 short-one border-no card-table text-black dataTable no-footer"
                    id="marketCapital"
                    role="grid"
                    aria-describedby="example6_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting_asc"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-sort="ascending"
                          aria-label="Rank: activate to sort column descending"
                          // style={{ width: 63 }}
                        >
                          Start Date
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Coin: activate to sort column ascending"
                          // style={{ width: "118.667px" }}
                        >
                          Pair
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Last Price: activate to sort column ascending"
                          // style={{ width: "76.6667px" }}
                        >
                          Invested, USDT
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Change (24h): activate to sort column ascending"
                          // style={{ width: 104 }}
                        >
                          Entry Price
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Volume (24h): activate to sort column ascending"
                          // style={{ width: "113.333px" }}
                        >
                          Current Price
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Volume (24h): activate to sort column ascending"
                          // style={{ width: "113.333px" }}
                        >
                          Take Profit Price
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Volume (24h): activate to sort column ascending"
                          // style={{ width: "113.333px" }}
                        >
                          DCA
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Volume (24h): activate to sort column ascending"
                          // style={{ width: "113.333px" }}
                        >
                          DCA Target Price
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Volume (24h): activate to sort column ascending"
                          // style={{ width: "113.333px" }}
                        >
                          Stop Loss Price
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Graph: activate to sort column ascending"
                          // style={{ width: "124.667px" }}
                        >
                          Profit
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example6"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Graph: activate to sort column ascending"
                          // style={{ width: "124.667px" }}
                        >
                          Profit, %
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {openPositions &&
                        openPositions.map((item, key) => (
                          <tr key={key} role="row" className="odd">
                            <td className="wspace-no sorting_1">
                              {convertTimestampToYmdHis(item.entry_timestamp)}
                            </td>
                            <td className="wspace-no">
                              {/* <i className="text-info flaticon-381-controls-7"></i> */}
                              <img alt="" src="./images/binance_logo.png" width="25px" />
                              <a href={item.binance_url} target="_blank" rel="noreferrer" className="text-black pl-2 hover_underline">{item.pair}</a>
                            </td>
                            <td>{item.invested === null ? "" : item.invested.toFixed(4)}</td>
                            <td>{item.entry_avg_price}</td>
                            <td>{item.current_price}</td>
                            <td>{item.take_profit_price}</td>
                            <td>
                              {item.dca_available === 1 && (
                                <Badge variant="success light">Yes</Badge>
                              )}
                              {item.dca_available === 0 && (
                                <Badge variant="info light">No</Badge>
                              )}
                            </td>
                            <td>{item.dca_target_price}</td>
                            <td>
                              {item.dca_available === 1 && item.dca_completed === 1 && (
                                <span>{item.stop_loss_price}</span>
                              )}
                              {item.dca_available === 1 && item.dca_completed === 0 && (
                                <OverlayTrigger
                                  trigger="click"
                                  placement="top"
                                  responsive={true}
                                  className="primary"
                                  overlay={
                                    <Popover
                                      id={`popover-positioned-top`}
                                    >
                                      <Popover.Content>
                                        The Stop Loss Price will be calculated if the DCA Target price is reached.
                                      </Popover.Content>
                                    </Popover>
                                  }
                                >
                                  <Button variant="info" size="xs" className="">
                                    ?
                                  </Button>
                                </OverlayTrigger>
                              )}
                              {item.dca_available === 0 && (
                                <span>{item.stop_loss_price === null ? "" : item.stop_loss_price.toFixed(4)}</span>
                              )}
                            </td>
                            <td>{item.profit === null ? "" : item.profit.toFixed(4)}</td>
                            <td>
                              <p className="mb-0 wspace-no">
                                <i
                                  className={item.profit_percent >= 0 ? "fa fa-caret-up scale5 mr-2 text-success" : "fa fa-caret-down scale5 mr-2 text-danger"}
                                  aria-hidden="true"
                                />
                                {item.profit_percent === null ? "" : item.profit_percent.toFixed(2)}%
                              </p>
                            </td>
                          </tr>
                        )
                      )}

                    </tbody>
                  </table>
                )}
              </div>
            )}

          </div>

          {loadingClosedPositions === false && activaTableName === 'closed' && (
            <div className="">
              <Pagination
                size="sm"
                className={`mt-4 pagination-gutter pagination-primary no-bg pagination-circle`}
              >
                <li className="page-item page-indicator">
                  <Link className="page-link" to="#" onClick={() => changePagePrev()}>
                    <i className="la la-angle-left" />
                  </Link>
                </li>

                {pageItems.length > 0 &&
                  pageItems.map((item, i) => (
                    <Pagination.Item key={i} active={item.p === active ? true : false} onClick={() => {changePage(item.p)}}>
                      {item.p}
                    </Pagination.Item>
                  )
                )}
                
                <li className="page-item page-indicator">
                  <Link className="page-link" to="#" onClick={() => changePageNext()}>
                    <i className="la la-angle-right" />
                  </Link>
                </li>
              </Pagination>

              {loadingBadge === true && (
                <div className="mt-3">
                  <Badge variant="info light">Loading</Badge>
                </div>
              )}
              
            </div>
          )}

        </div>
      </div>
    </>
  );
};

export default Positions;
