import React from "react";
import { Link } from "react-router-dom";
// image
import logo from "../../images/logo/logo_full.png";

const Register = () => {
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-12">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={logo} width="120" alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Privacy Policy</h4>

                    <p>AUS CLEANING & MAINTENACE PTY LTD (“BETTR Trade”, “we”, “our” or “us”) gathers information about you from various sources to deliver the Services to you, safeguard our legitimate interests, enhance and analyze our Services, communicate with you, and adhere to our legal and regulatory obligations. This Privacy Policy (“Privacy Policy”) outlines how we handle the personally identifiable information we collect when you access or use our Services.</p>
                    <p>Periodically, we may update or modify this Privacy Policy to reflect changes in legislation, our practices of collecting and utilizing Personal Data, the features of the Services, or advancements in technology. This Privacy Policy does not encompass the practices of entities we do not own or control, or individuals we do not oversee.</p>
                    <p>Our Privacy Policy is applicable to all Services we provide, except for any products, applications, or services with separate privacy policies that do not integrate this Privacy Policy. As per the data protection laws of the European Union (“EU”) and other pertinent jurisdictions where Customers are situated (“Data Protection Laws”), BETTR Trade is a data controller (i.e., the company responsible for, and managing the processing of, your Personal Data).</p>
                    <p>By accessing and/or using our Services, you acknowledge acceptance of this Privacy Policy, along with the terms and provisions outlined in the Terms of Service. The terminology used in this Privacy Policy holds the same definitions as in the Terms of Service, unless specified otherwise herein. To clarify, the term “Services” encompasses all services offered by BETTR Trade, including but not limited to the Website https://bettrtrade.com, desktop and mobile applications, Copy Trading Software, Crypto Trading Bot, and any other service provided by BETTR Trade periodically (referred to as the “Services”).</p>
                    <p><b>What data we collect</b></p>
                    <p>1. Email accounts to help identify your contacts on the Services, First and Last name.</p>
                    <p>2. Correspondence between BETTR Trade and you, including emails, social media messages, job applications, and customer support inquiries;</p>
                    <p>3. Current and past details regarding your geographical location, GPS coordinates, transaction locations, and IP addresses utilized when accessing our Services. We may utilize third-party services like the Google Maps API to obtain location data, with Google using various technologies to determine location (refer to Google's Privacy Policy for further details);</p>
                    <p>4. Device and software information such as IP address, browser type, Internet service provider, platform type, device type, operating system, date and time stamps, unique IDs allowing identification of your browser, mobile device, or account, and similar data.</p>
                    <p>We may monitor, record, and retain your personal information to ensure your safety or that of other Customers, comply with relevant laws, assist regulatory or law enforcement efforts, protect and defend our rights and property, or for other reasons relating to our provision of Services. By utilizing the Services, you consent to the recording, storage, and disclosure of such information for these purposes.</p>
                    <p>Our Services are not accessible to individuals under the age of eighteen (18) ("Minors"). BETTR Trade has instituted controls to prevent Minors from accessing or using our Services knowingly and intentionally. Therefore, we will never knowingly collect Personal Data from Minors. If you are a parent or guardian of a Minor and suspect or are aware that a Minor has provided us with Personal Data, please notify us promptly. Upon becoming aware of collecting Personal Data from Minors, with or without parental consent verification, we will take steps to delete such information from our systems.</p>
                    <p><b>How we collect Personal Data</b></p>
                    <p>To the maximum extent permitted by applicable Data Protection Laws, we gather Personal Data concerning you and any other individual whose information you furnish to us when you:</p>
                    <p>1. Utilize or access our Services;</p>
                    <p>2. Establish an Account;</p>
                    <p>3. Fill out online forms (including callback requests), engage in surveys, contribute to forums, download materials such as white papers or publications, or participate in any interactive sections available within our Services;</p>
                    <p>4. Engage with us via social media platforms;</p>
                    <p>5. Provide your contact information when registering for or accessing any services we provide, or when updating such details; and</p>
                    <p>Reach out to us or establish connections with us, whether online or offline.</p>
                    <p>6. We also collect Personal Data in cases where you partially complete or abandon any information provided through our Services or other online forms. This information may be used to prompt you to complete any outstanding details or for marketing purposes.</p>
                    <p>Furthermore, we may obtain information from the devices and applications (including mobile devices) you or your users employ to access our Services. This includes device identification numbers, location data, connection statistics such as page views and traffic to/from our Services, referral URLs, advertisement data, IP addresses, browsing history, and web log details. We will seek your consent before gathering such data, typically through cookies or similar technologies (as outlined below).</p>
                    <p>If you plan to furnish us with Personal Data about another individual, you are accountable for ensuring compliance with any obligations and consent requirements stipulated by applicable Data Protection Laws. As mandated by these laws, you must obtain explicit consent from them beforehand and explain how we collect, use, disclose, and retain their Personal Data, or direct them to review our Privacy Policy.</p>
                    <p><b>How we use your Personal Data</b></p>
                    <p>To the maximum extent permitted by applicable Data Protection Laws, we utilize your information for the following purposes:</p>
                    <p>1. Fulfilling any requests or orders for information or Services that you have made;</p>
                    <p>2. Cross-referencing information to ensure accuracy and authenticity with third-party sources;</p>
                    <p>3. Provision, maintenance, safeguarding, and enhancement of our Services;</p>
                    <p>4. Overseeing, tracking, and administering your usage of the Services to deliver a tailored, personalized user experience;</p>
                    <p>5. Managing our interactions and relationship with you, including customer service and technical support activities;</p>
                    <p>6. Conducting internal tests on our Services or systems to enhance security and performance, ensuring any data used for testing is de-identified;</p>
                    <p>7. Sending you any necessary information to comply with regulatory or legal obligations;</p>
                    <p>8. Adhering to relevant regulations such as Know Your Customer, Anti-Money Laundering, Counter-Terrorism Financing, and Anti-Corruption laws;</p>
                    <p>9. Detecting, preventing, investigating, or addressing crime, illegal activities, or Prohibited Activities, and safeguarding our legal rights;</p>
                    <p>10. Inviting you to participate in customer research or provide feedback on our Services;</p>
                    <p>11. Analyzing data for statistical purposes and benchmarking, ensuring individual privacy is maintained through aggregation;</p>
                    <p>12. Delivering advertising, marketing, or relevant information to you, including in-product messaging;</p>
                    <p>13. Offering joint content and services with third parties with whom you have a separate relationship, such as social media providers; and</p>
                    <p>14. Disclosing your Personal Data to governmental authorities as required by law. Additionally, we may retain your information after certain events, such as fund withdrawal, Account closure, or declined registration, as required by Data Protection Laws or for legitimate purposes.</p>
                    <p>Our Services may utilize technology to:</p>
                    <p>1, Verify specific information from your devices or systems relevant to your use of our Services and troubleshoot any issues;</p>
                    <p>2. Gather information regarding technical errors or malfunctions with our Services;</p>
                    <p>3. Monitor how you utilize the features of our Services; and</p>
                    <p>4. Collect statistical data about the operating system and environment from which you access our Services.</p>
                    <p>If you follow BETTR Trade on social networks, the processing of your Personal Data will adhere to the policies, terms of use, privacy policies, and regulations of the respective social network platforms that you have accepted.</p>
                    <p>We use your Personal Data to effectively manage your social media presence, keep you informed about BETTR Trade activities and Services, and for other purposes permitted by the social network platforms' rules.</p>
                    <p>Under no circumstances will BETTR Trade use social media follower profiles for individualized advertising.</p>
                    <p><b>Parties with whom we may share your Personal Data</b></p>
                    <p>We may disclose your Personal Data to:</p>
                    <p>1. Third-Party Internet and Marketing Services Providers, including but not limited to MailChimp, Active Campaign, Facebook, Twitter, Discord, Telegram, Google Cloud, and Google Analytics;</p>
                    <p>2. Government and State Authorities in response to subpoenas, court orders, legal processes, law enforcement requests, legal claims, or government inquiries, as well as to protect and defend the rights, interests, safety, and security of BETTR Trade, our affiliates, Customers, or the public;</p>
                    <p>3. BETTR Trade Entities and Affiliates;</p>
                    <p>4. Third Parties in connection with corporate transactions such as the sale of a website, mergers, consolidations, reorganizations, financing, changes of control, acquisitions of all or part of our business by another company or third party, asset sales, initial public offerings, or in the unlikely event of bankruptcy or similar proceedings.</p>
                    <p><b>Data Retention</b></p>
                    <p>We will keep your Personal Data for as long as necessary to fulfill contractual obligations, comply with legal and regulatory requirements, and safeguard our legitimate interests. In particular, we reserve the right to retain your Personal Data to comply with applicable Know Your Customer, Anti-Money Laundering, Counter-Terrorism Financing, and Anti-Corruption laws and regulations for a minimum of seven (7) years. Additionally, we will retain your data for the duration necessary to address legal proceedings, complaints, and disputes to protect our legitimate interests.</p>
                    <p><b>Persons who may access your Personal Data</b></p>
                    <p>Authorized personnel at BETTR Trade, including but not limited to our Compliance Officers, will have access to your Personal Data on a need-to-know basis. These individuals are bound by confidentiality and non-disclosure agreements and must adhere to strict company policies regarding data access and use.</p>
                    <p>We may occasionally engage third-party service providers to assist BETTR Trade with implementing the KYC/AML & CTF Policy and ensuring compliance with applicable Know Your Customer, Anti-Money Laundering, Counter-Terrorism Financing, and Anti-Corruption laws and regulations. When doing so, we will enter into data processing agreements with these third parties to ensure the security and protection of your Personal Data against breaches. These data processors will only handle your Personal Data as needed to provide the services for which they were engaged.</p>
                    <p>We may also provide your Personal Data to competent authorities upon request, as legally required, or as necessary to defend our rights in legal proceedings or investigations.</p>
                    <p><b>Data Security</b></p>
                    <p>To safeguard your Personal Data, BETTR Trade employs all reasonable measures and adheres to industry best practices to prevent loss, misuse, unauthorized access, disclosure, alteration, or destruction.</p>
                    <p>Beyond the purposes outlined in this section, we may also utilize the information we collect to provide you with targeted, interest-based advertising, marketing (including in-product messaging), or information that may be beneficial to you. This targeting is based on your use of the Services or other data we have about you. Depending on the Services, you may have options to customize these features to match your preferences.</p>
                    <p><b>Legal basis for processing in the EU</b></p>
                    <p>In the EU, we collect your Personal Data for the following reasons:</p>
                    <p>1. With your consent, for marketing purposes, which you can revoke at any time;</p>
                    <p>2. To fulfill any contract we have with you or to provide access to our Services;</p>
                    <p>3. To serve our legitimate business interests (or those of a third party) provided these are not overridden by your interests and fundamental rights; and</p>
                    <p>4. To comply with legal or regulatory obligations in the EU or elsewhere.</p>
                    <p>You are entitled to the following rights:</p>
                    <p>1. Access to your Personal Data;</p>
                    <p>2. Correction of inaccurate or incomplete Personal Data;</p>
                    <p>3. Deletion or removal of your Personal Data when there is no valid reason for further processing;</p>
                    <p>4. Restriction of processing of your Personal Data when appropriate;</p>
                    <p>Data portability to facilitate the transfer of your Personal Data from one platform to another;</p>
                    <p>5. Objection to the processing of your Personal Data under certain circumstances; and</p>
                    <p>6. Rights concerning profiling and automated decision-making related to your Personal Data.</p>
                    <p>These rights are personal and must be exercised directly by the individual. Any customer, user, subscriber, or collaborator who has provided their data can contact BETTR Trade to request information about the stored data, how it was obtained, request corrections, data portability, object to processing, limit its use, or request deletion of the data in BETTR Trade’s records.</p>
                    <p><b>Content from other Services</b></p>
                    <p>The Services may contain embedded content such as videos, images, articles, etc. This embedded content functions exactly as it would if you were visiting the other websites directly.</p>
                    <p>These external websites may collect information about you, use cookies, embed additional third-party tracking codes, and monitor your interactions with the embedded content.</p>
                    <p><b>Cookies policy</b></p>
                    <p>Our Services use cookies. Upon accessing our Services, you will be notified of our cookie usage through a pop-up banner.</p>
                    <p>1. About Cookies</p>
                    <p>Cookies are files with unique identifiers that web servers send to internet browsers. These cookies are then sent back to the server each time the browser requests a page. Web servers utilize cookies to recognize and track users as they navigate through different pages of our Services and to identify returning users.</p>
                    <p>Cookies can be classified as either "persistent" or "session" cookies. A persistent cookie is a text file sent by a web server to a browser, stored by the browser, and remains valid until its set expiration date (unless deleted by the user beforehand). A session cookie, in contrast, expires at the end of a user's session when the browser is closed.</p>
                    <p>2. Cookies Used by the Services</p>
                    <p>Our Services, including our website and mobile applications, use both session and persistent cookies.</p>
                    <p>We will send you the following cookies:</p>
                    <p>Session Cookies</p>
                    <p>Google Analytics: This cookie helps us identify unique users, unique sessions, regulate the rate of requests, and store information about user sessions and campaigns.</p>
                    <p>The session cookie is temporary and expires when you close your browser, whereas the Google Analytics cookie is persistent and remains until its set expiration date.</p>
                    <p><b>How we use cookies</b></p>
                    <p>Cookies do not contain personally identifiable information, but we can link the personal data we store about you with the information obtained and stored from cookies. We use the information gathered from our cookies for the following purposes:</p>
                    <p>1. Recognize your computer when you access and use our Services.</p>
                    <p>2. Enhance the usability of our Services.</p>
                    <p>3. Analyze how our Services are used.</p>
                    <p>4. Manage and administer our Services.</p>
                    <p>Third-Party Cookies</p>
                    <p>When using our Services, you may also receive cookies from third parties. Our service providers may send you cookies and use the information they collect for the following purposes:</p>
                    <p>1. Track your browsing activity across different Services.</p>
                    <p>2. Create a profile of your internet browsing habits.</p>
                    <p>3. Select and display specific ads that may interest you.</p>
                    <p>Deleting and Blocking Cookies</p>
                    <p>You can restrict, block, or delete cookies from our Services at any time. To do this, you need to adjust your browser settings for cookies through the “Preferences,” “Options,” or “Tools” menu (the names of these menus or the procedures to access cookie settings may vary depending on the browser). Most browsers allow you to refuse cookies and to delete existing cookies. The methods for doing this vary by browser and version, but you can find up-to-date information on blocking and deleting cookies through the following links:</p>
                    <p>1. https://support.google.com/chrome/answer/95647 (Chrome);</p>
                    <p>2. https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-Services-preferences (Firefox);</p>
                    <p>3. https://help.opera.com/en/latest/security-and-privacy/ (Opera);</p>
                    <p>4. https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies (Internet Explorer);</p>
                    <p>5. https://support.apple.com/en-gb/guide/safari/manage-cookies-and-Services-data-sfri11471/mac (Safari); and</p>
                    <p>6. https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy (Edge).</p>
                    <p><b>Navigation</b></p>
                    <p>When accessing or using the Services, non-identifiable data may be collected. This can include your IP address, geolocation, records of how services are used, browsing habits, and other data that cannot be used to identify you personally.</p>
                    <p>The Services utilize the following third-party analytics service: Google Analytics.</p>
                    <p>BETTR Trade uses the information gathered to compile statistical data, analyze trends, manage the Services, study navigation patterns, and collect demographic information.</p>
                    <p><b>Accuracy and Veracity of Personal Data</b></p>
                    <p>You agree that the information you provide to BETTR Trade is correct, complete, accurate, and up-to-date. You are solely responsible for the truthfulness and accuracy of the data you submit when accessing or using the Services, thereby absolving BETTR Trade of any liability in this regard.</p>
                    <p><b>Acceptance and Consent</b></p>
                    <p>You acknowledge that you have been informed about the conditions regarding the protection of Personal Data and you agree to the processing of this data by BETTR Trade as outlined in this Privacy Policy.</p>
                    <p><b>Revocability</b></p>
                    <p>To exercise your rights of access, rectification, cancellation, portability, and opposition, you must send an email to info@bettrtrade.com along with a valid proof of ID such as a government-issued identification document. Your rights do not extend to any Personal Data that BETTR Trade is required to retain for administrative, contractual, legal, or security purposes.</p>
                    <p><b>Change of Operatorship</b></p>
                    <p>In the event of incorporation, acquisition, merger, or any other situation that leads to a change in the operator of the Services, you expressly consent to the transfer of your registration data and information from BETTR Trade to the new operator. BETTR Trade will inform you of such changes as required.</p>
                    <p><b>Changes to the Privacy Policy</b></p>
                    <p>BETTR Trade reserves the right to update this Privacy Policy to reflect legislative changes and industry practices. BETTR Trade may notify you of such changes via email or through the Services.</p>
                    <p><b>Services Operator</b></p>
                    <p>The Services are operated by AUS CLEANING & MAINTENACE PTY LTD, an Australian Private Company, located at NSW 2525.</p>
                    <p><b>Contact</b></p>
                    <p>If you have any questions or complaints about the Privacy Policy, you can reach our data protection officer via email at info@bettrtrade.com.</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
