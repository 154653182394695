import React from "react";
import { Link } from "react-router-dom";
// image
import logo from "../../images/logo/logo_full.png";

const Register = () => {
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-12">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={logo} width="120" alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Terms of Service Agreement</h4>

                    <p>This Terms of Service agreement constitutes a legal and binding contract between AUS CLEANING & MAINTENACE PTY LTD, Australian Private Company, including its successors and assigns (referred to as "BETTR Trade," "our," "us," or "we"), and the individual or entities (referred to as the "User," "you," or "your") who access and/or utilize the Services.</p>
                    <p>The Terms of Service, along with all other agreements, acknowledgments, and authorizations by the User related to account registration or maintenance with BETTR Trade and the use of the Services—such as the API License Agreement, the Risk Warning, and the Privacy Policy available on our website (collectively referred to as the “Terms”)—define the conditions under which BETTR Trade will deliver the Services to the User.</p>
                    <p>The Terms outline the respective rights and obligations of both parties concerning the Services. Upon accepting the terms and conditions herein, both parties agree to be bound by these terms. The User acknowledges that the following rights and obligations will govern the relationship between BETTR Trade and the User.</p>
                    <p>PLEASE READ THE TERMS CAREFULLY AS THEY GOVERN YOUR RELATIONSHIP WITH BETTR Trade. IF YOU DISAGREE WITH ANY PART OF THE TERMS, YOU MUST NOT ACCESS ANY SERVICES OR PRODUCTS PROVIDED BY BETTR Trade. THIS AGREEMENT INCLUDES A BINDING ARBITRATION CLAUSE AND CLASS ACTION WAIVER THAT AFFECT YOUR RIGHTS REGARDING DISPUTE RESOLUTION. PLEASE REVIEW IT THOROUGHLY.</p>
                    <p>BY REGISTERING AND/OR MAINTAINING AN ACCOUNT AND/OR ACCESSING AND/OR USING OUR SERVICES, YOU ACKNOWLEDGE THAT: (I) YOU HAVE READ AND UNDERSTAND YOUR OBLIGATIONS AND RIGHTS UNDER THE TERMS, AND AGREE THAT THIS TERMS OF SERVICE, THE API LICENSE AGREEMENT, THE RISK WARNING, AND THE PRIVACY POLICY CONSTITUTE THE COMPLETE TERMS AND CONDITIONS OF YOUR RELATIONSHIP WITH BETTR Trade; (II) YOU ACCEPT FULL RESPONSIBILITY FOR MAKING ALL DECISIONS REGARDING TRANSACTIONS FOR YOUR ACCOUNT; (III) YOU ARE AWARE OF THE RISKS INVOLVED IN TRADING DIGITAL ASSETS AND DIGITAL ASSET DERIVATIVES; (IV) YOU ASSUME ALL RISKS RELATED TO THE USE OF YOUR ACCOUNT AND TRADING IN DIGITAL ASSETS AND DIGITAL ASSET DERIVATIVES; (V) YOU ARE AWARE OF AND ACCEPT ALL RISKS ASSOCIATED WITH USING TRADING BOTS, AND SOCIAL TRADING; (VI) BETTR Trade WILL NOT BE LIABLE FOR ANY SUCH RISKS OR NEGATIVE OUTCOMES; AND (VII) YOU DECLARE THAT, CONSIDERING YOUR CURRENT AND ANTICIPATED FINANCIAL RESOURCES, YOU ARE WILLING AND ABLE TO BEAR THE SIGNIFICANT FINANCIAL RISKS INVOLVED IN TRADING DIGITAL ASSETS, FUTURES, DIGITAL ASSET DERIVATIVES, AND USING TRADING BOTS.</p>
                    <p>IT IS YOUR RESPONSIBILITY TO OBTAIN ALL NECESSARY INFORMATION ABOUT TRADING IN DIGITAL ASSETS, DIGITAL ASSET DERIVATIVES, AND SOCIAL TRADING, AS WELL AS ABOUT THE TERMS. ENSURE THAT ALL RISKS AND ARRANGEMENTS ARE DISCUSSED AND CLEARLY UNDERSTOOD BEFORE ENGAGING IN ANY TRADING ACTIVITIES OR USING THE SERVICES.</p>
                    <p><b>1.DEFINITIONS AND INTERPRETATION</b></p>
                    <p>1.1. Definitions</p>
                    <p>“Account” refers to the Account created by BETTR Trade for the User, granting access to the Services.</p>
                    <p>“Agreement” encompasses these Terms of Service along with all other agreements and authorizations completed by the User related to registering or maintaining an account with BETTR Trade and using the Services.</p>
                    <p>“API” refers to the Application Programming Interfaces supplied by BETTR Trade.</p>
                    <p>"Content" encompasses all information, documents, or images found on the Website, its associated sites and mobile apps, data accessible via any APIs, and any information, documents, or images on BETTR Trade accounts across various third-party social media platforms, including but not limited to Twitter, Instagram, Telegram, Facebook, Discord, Github, Medium, YouTube, and Reddit.</p>
                    <p>"Digital Asset Derivatives" refer to agreements between two or more parties whose worth derives from an underlying Digital Asset. This category encompasses various financial instruments, including but not restricted to Options, Perpetual Swaps, Futures, as well as other instruments like forwards and contracts for difference.</p>
                    <p>"Digital Asset Exchange" refers to third-party-operated platforms for trading digital assets, including digital asset exchanges and trading platforms.</p>
                    <p>"Digital Assets" are encrypted currencies, digital tokens, or cryptocurrencies based on distributed ledger technology, which can be traded or transferred digitally. It should be noted that Bitcoin and Ether are examples of Digital Assets.</p>
                    <p>"Exchange Master Account" refers to an account maintained by BETTR Trade within a Digital Asset Exchange.</p>
                    <p>"Exchange Sub-Account" signifies an individual user's separate account managed within the Exchange Master Account, which is accessible through the user's main Account.</p>
                    <p>"External Exchange Account" refers to an account held by a User within a Digital Asset Exchange, linked to the User Account.</p>
                    <p>"Intellectual Property Rights" encompass various proprietary rights, including but not limited to patents, database rights, trademarks, trade secrets, trade names, service marks, domain names, design elements, graphical images, and copyrightable works. This includes software applications, source code, visual design elements, and various media materials.</p>
                    <p>"KYC/AML & CTF Policy" refers to the set of measures and protocols established by BETTR Trade to identify and deter activities related to money laundering, terrorist financing, and corruption.</p>
                    <p>"Prohibited Activities" refers to the activities outlined in Sections 5, 7 and 8 of these Terms of Service.</p>
                    <p>"Prohibited Jurisdiction" refers to (i) specific regions and countries including but not limited to the People’s Republic of China, Afghanistan, Belarus, Canada, Central African Republic, Congo, the Democratic Republic of the Congo, Republic of the Cote D’Ivoire, Crimea region of Ukraine, Cuba, El Salvador, Eswatini, Gambia, Iran, Iraq, Liberia, Libya, Mainland China, Malawi, Mali, Moldova, Myanmar, Niger, North Korea, Palestinian Territory, Russian-controlled regions of Ukraine (currently including the Crimea, Donetsk, and Luhansk regions), Saint Vincent and the Grenadines, Singapore, South Sudan, Sudan, Syria, United Kingdom, United States, Uzbekistan, Venezuela, Yemen, Zambia, Zimbabwe; and (ii) any state, country, or jurisdiction that is under sanction and/or embargo by the United States of America or the European Union.</p>
                    <p>"Services" refers to all the services offered by BETTR Trade, as outlined in Section 6 of this document.</p>
                    <p>"Signal Providers" refers to trading algorithms that furnish trading signals, including suggestions to initiate or conclude trading positions in Digital Assets and Digital Asset Derivatives, to users.</p>
                    <p>"U.S." or "US" or "United States" encompasses all states within the United States of America, including the District of Columbia; the Commonwealth of Puerto Rico; the U.S. Virgin Islands; Guam; the Commonwealth of the Northern Mariana Islands; and all other territories and possessions of the United States of America.</p>
                    <p>"US Person" denotes (i) an individual who is a U.S. citizen; (ii) an individual who holds lawful permanent residency in the U.S.; (iii) a protected individual under section 1324b(a)(3) of the U.S. Immigration and Nationality Act or possesses a U.S. government-issued passport; (iv) a corporation, company, partnership, or other legal entity established or organized under U.S. laws; (v) an individual or entity conducting business within the U.S. and earning income from U.S.-based sources; (vi) the estate of a deceased individual who was a U.S. citizen, lawful permanent resident, or a protected individual under section 1324b(a)(3) of the U.S. Immigration and Nationality Act; (vii) a trust subject to U.S. court supervision, where one or more U.S. Persons have substantial control over its administration, decisions, or are beneficiaries; (viii) an entity established outside the U.S. in which any U.S. Person, individually or collectively, holds a 50 percent or greater equity interest, majority board seats, or controls its actions, policies, or operations; or (ix) an individual subject to U.S. taxation.</p>
                    <p>"User," "you," or "your" refers to the individual or individuals who have consented to abide by the Terms.</p>
                    <p>"User Content" refers to any information, feedback, suggestions, ideas, or materials regarding BETTR Trade or the Services that you supply to BETTR Trade via the Website, the Services, or any other avenue, including but not limited to blogs, message boards, forums, and APIs, whether submitted directly or through a third party.</p>
                    <p>"Website" refers to https://bettrtrade.com, along with its subdomains, associated domains, mobile applications, and all downloadable materials and emails associated with it.</p>
                    <p>1.2. Construction. In this Agreement, unless the context otherwise requires</p>
                    <p>a) words importing the singular include the plural and vice versa;</p>
                    <p>b) words importing a gender include both gender and the neuter;</p>
                    <p>c) words importing persons include companies, associations and bodies of persons whether corporate or not;</p>
                    <p>d)the words: “may” shall be construed as permissive; “shall” or “will” shall be construed as imperative;</p>
                    <p>e) the term “include” or “includes” means includes, without limitation, and “including” means including, without limitation;</p>
                    <p>f) the terms “hereof”, “herein” and “hereunder” refer to the Terms as a whole and not to any particular provision of the Terms; and</p>
                    <p>g) the headings contained in these Terms are for reference purposes only, and shall not affect in any way the meaning or interpretation of the Terms.</p>
                    <p><b>2. AGREEMENT</b></p>
                    <p>BETTR Trade retains the authority, at its sole discretion, to amend or substitute these Terms at any juncture, with or without prior notice to the User. Such modifications shall take effect immediately upon being publicly posted. Your ongoing utilization of our Services post any such alteration signifies your consent to the revised Terms. Should you disagree with any amendment to these Terms, you are obligated to discontinue using the Services. BETTR Trade advises you to regularly review the Terms to ensure comprehension of the conditions and stipulations pertinent to your acquisition, access, and utilization of the Service.</p>
                    <p><b>3. DISCLAIMER</b></p>
                    <p>BETTR Trade does not offer financial guidance, investment management, or any advisory services. It is not a banking institution, investment manager, or financial service provider. Instead, BETTR Trade functions as a software as a service (SaaS) provider, facilitating a platform for Users to engage with Signal Service Providers and Digital Asset Exchanges.</p>
                    <p>Your use of the Services entails inherent risks, and you bear full responsibility for any resultant losses, damages, or expenses. The Services are not to be construed as investment advice, nor do they entail any marketing, promotion, or offer of products or investments to you or any third party. They are provided "AS IS" and "AS AVAILABLE."</p>
                    <p>The Services come with no warranties, whether explicit or implied, including but not limited to merchantability, fitness for a particular purpose, non-infringement, or consistent performance. BETTR Trade disclaims all liability for any losses or damages, including special, incidental, or consequential damages. Moreover, BETTR Trade does not guarantee the accuracy, completeness, reliability, or timeliness of the Services, nor do they ensure freedom from viruses or other harmful components.</p>
                    <p>By accessing or utilizing our Services, you agree that BETTR Trade bears no liability, direct or contingent, to you or any third parties, concerning the accuracy, quality, security, or continued availability of the Services. This includes any interruptions, delays, or errors in communication between BETTR Trade and you, irrespective of cause.</p>
                    <p><b>4. COMMUNICATION</b></p>
                    <p>a) You understand, acknowledge and agree that the method of communication between BETTR Trade and you shall be electronically via email or via the Website. We will use the email address or your username registered on your account as our means of communicating with you. You agree to keep your email address up-to-date, check your email frequently and immediately notify us if there are any changes.</p>
                    <p>b) You understand, acknowledge and agree that delivery of any communication to the email address registered in your account is considered valid, and will be deemed to have been acknowledged as authorized, correct, approved, and confirmed by you unless we have received written notice to the contrary within three (3) business days from the date the communication was sent. If any email communication is returned as undeliverable, BETTR Trade retains the right to block your access to your Account until you provide and confirm a new and valid email address.</p>
                    <p>c) You understand, acknowledge and agree that BETTR Trade shall not be responsible to you for information obtained through non-official channels of BETTR Trade. If you have any questions or concerns related to the truthfulness of information sent in the name of BETTR Trade, please contact us immediately.</p>
                    <p>d) You understand, acknowledge and agree that it is a violation of these Terms to make any misstatement of a material fact to BETTR Trade, or the BETTR Trade Entities.</p>
                    <p><b>5. USER REPRESENTATIONS AND WARRANTIES; ELIGIBILITY</b></p>
                    <p>By accessing and/or using the Services, you represent and warrant that:</p>
                    <p>a) you are at least 18 years old or of legal age to form a binding contract under applicable law, are an individual, legal person or other organization with full legal capacity and authority to enter into these Terms;</p>
                    <p>b) if you are entering into these Terms on behalf of a legal entity of which you are an employee or agent, you have all necessary rights and authority to bind such legal entity, and (i) you are legally permitted to use the Services in your jurisdiction, and (ii) you are legally permitted to own cryptocurrencies in your jurisdiction;</p>
                    <p>c) you are responsible for ensuring compliance with the laws of your jurisdiction and acknowledge that BETTR Trade is not liable for your compliance with such laws,</p>
                    <p>d) your use of the Services does not constitute a breach of the laws of your jurisdiction;</p>
                    <p>e) you understand the inherent risks associated with Digital Assets, and trading Digital Assets, and Digital Asset Derivatives;</p>
                    <p>f) you have a working understanding of the usage of Digital Assets, smart contract based tokens, and blockchain-based software systems;</p>
                    <p>g) you have sufficient investment knowledge and experience and the capacity to take risks arising from trading in Digital Assets, and Digital Asset Derivatives;</p>
                    <p>h) you have knowledge and experience in highly volatile markets;</p>
                    <p>k) you trade with funds you can afford to lose, and have a high-risk tolerance;</p>
                    <p>l) you will not carry out any activity that (i) involves proceeds from any illegal or unlawful activity (including money laundering or terrorism financing); or (iii) violates, or could violate, any applicable law;</p>
                    <p>m) you will not be involved or initiate any form of market manipulation;</p>
                    <p>n) you will not use, or misuse, the Services in any way which may impair the functionality of the </p>
                    <p>Services, or other systems used to deliver the Services or impair the ability of any other user to use the Services or Trading Platform;</p>
                    <p>o) you will not transmit or input into the Website any files that may damage any other person’s computing devices or software; content that may be offensive; or material or data in violation of any law;</p>
                    <p>p) you own and have full control of the Digital Asset wallet address used for withdrawals of your Account;</p>
                    <p>q) you are the legal owner (or an authorized agent of the legal owner) of the funds you deposit to your Account, and that these funds are derived from a legitimate source;</p>
                    <p>r) you will not use any method or services to mask your internet protocol address or your internet traffic or current location or real internet connection, including but not limited to virtual private networks, proxy servers, Tor browser;</p>
                    <p>s) you are not a national and/or resident of a Prohibited Jurisdiction;</p>
                    <p>t) you are not by reason of your nationality, domicile, citizenship, residence or otherwise subject to the laws of a Prohibited Jurisdiction;</p>
                    <p>u) you are are not, and have not been involved in a transaction with a person who is, on any trade or economic sanctions lists, including, but not limited to, the UN Security Council Sanctions list, designated as a “Specially Designated National” by OFAC (Office of Foreign Assets Control of the U.S. Treasury Department) or placed on the U.S. Commerce Department’s “Denied Persons List”. BETTR Trade maintains the right to restrict or deny the provision of Services in certain countries and/or to certain natural persons and/or juristic persons at its sole discretion.</p>
                    <p><b>6. SERVICES; SIGNAL PROVIDERS</b></p>
                    <p>6.1. Services. Your BETTR Trade Account grants access to the following Services, subject to your adherence to the Terms, unless stated otherwise:</p>
                    <p>a) the Website;</p>
                    <p>b) Crypto Trading Bot;</p>
                    <p>c) any other service periodically offered by BETTR Trade.</p>
                    <p>6.2. Margin Trading. Digital Asset Exchanges accessible via the Trading Terminal may allow Users to borrow funds for trading Digital Assets and Digital Asset Derivatives ("Margin Trading"). This practice entails significant risk, potentially resulting in substantial losses. Thus, Digital Asset Exchanges might necessitate Users to deposit collateral ("Margin") and maintain a minimum Margin amount to sustain a Margin Trading position ("Minimum Margin Requirement"). While Margin Trading can yield substantial gains, it also poses the risk of significant losses or liquidation due to market fluctuations. By engaging in Margin Trading via the Services, you acknowledge and agree that:</p>
                    <p>a) Financing for Margin Trading is provided solely at the discretion of the Digital Asset Exchange;</p>
                    <p>b) BETTR Trade holds no authority over the availability of Margin Trading, Minimum Margin Requirements, or forced-liquidation procedures, thus assumes no liability for any resulting damages from your use of Margin Trading via the Account;</p>
                    <p>c) You have comprehensively reviewed and understood the terms and conditions of Margin Trading as outlined by the relevant Digital Asset Exchange;</p>
                    <p>d) Failing to meet Minimum Margin Requirements may lead to the liquidation of open positions and subsequent losses; and</p>
                    <p>e) Digital Asset Exchanges may establish various rules and policies governing Margin Trading, such as minimum account size, investment duration, commissions, leverage size, stop-loss rules, and margin call regulations. BETTR Trade bears no discretion over such rules.</p>
                    <p>6.3. Trading Fees. Digital Asset Exchanges levy non-refundable fees for opening and closing trading positions via the Trading Terminal ("Trading Fees"). These fees are determined solely by the relevant Digital Asset Exchange, and BETTR Trade holds no authority over them. You acknowledge and agree that it is your responsibility to understand and manage these Trading Fees. By authorizing BETTR Trade, you agree to allow automatic deduction of Trading Fees from your Internal BETTR Trade account balance. Additionally, BETTR Trade reserves the right to impose Fees for using the Services at its discretion.</p>
                    <p>6.4. Crypto Trading Bot. BETTR Trade assumes no responsibility for the actions or trading strategies of Signal Providers.</p>
                    <p>6.5. Confidentiality. BETTR Trade is not obligated to disclose personal information about Signal Providers or their trading strategies.</p>
                    <p>6.6. Investment Services. BETTR Trade does not provide asset management, personalized investment advice, or portfolio management services. Any information provided regarding copy trading or trading signals is not construed as advice of any kind.</p>
                    <p>6.7. Non-Interference. Users are prohibited from interfering with Signal Providers' or Copy Traders' trading operations or challenging their trading strategies.</p>
                    <p>6.8. Liability. BETTR Trade bears no responsibility for any potential losses or missed profits.</p>
                    <p>6.9. Fees. BETTR Trade reserves the right to charge commissions, such as Success Fees on trading profits, or Subscription Fees, at its discretion without prior notice and without liability.</p>
                    <p>6.10. Complaints. BETTR Trade will not entertain complaints or compensation requests regarding the performance of Copy Traders or Signal Providers.</p>
                    <p><b>7. ACCOUNT</b></p>
                    <p>7.1. Identity Verification. When you access or use the Services or create an Account, we will request your name, address, date of birth, and other details, along with documents to verify your identity in accordance with our KYC/AML & CTF Policy. It's essential to provide accurate and current information at all times. Failure to comply constitutes a breach of the Terms, leading to potential termination of your Account. Additionally, you agree to furnish any additional information we may require for identity verification and prevention of financial crimes, such as a government-issued ID, proof of address, and source of funds.</p>
                    <p>7.2. Account Security. You are solely responsible for safeguarding your Account credentials and the devices used to access the Services, ensuring that unauthorized individuals cannot access your Account. We bear no liability for any losses resulting from your failure to protect your Account.</p>
                    <p>7.3. Prohibited Activities. While using the Account or Services, you must refrain from:</p>
                    <p>a) Using the Services for commercial purposes or on behalf of others;</p>
                    <p>b) Violating any laws or regulations applicable to your use of the Services;</p>
                    <p>c) Engaging in market abuse, fictitious transactions, or other fraudulent activities;</p>
                    <p>d) Assisting others in violating the Terms or any laws;</p>
                    <p>e) Participating in spoofing or any manipulative trading practices;</p>
                    <p>f) Involvement in money laundering, terrorism financing, or fraudulent activities;</p>
                    <p>g) Infringing upon intellectual property rights;</p>
                    <p>h) Overloading our infrastructure or interfering with our systems;</p>
                    <p>i) Uploading malicious software or viruses;</p>
                    <p>j) Attempting unauthorized access to the Services or associated systems;</p>
                    <p>k) Using automated tools to access or monitor our properties;</p>
                    <p>l) Testing vulnerabilities or breaching security measures;</p>
                    <p>m) Providing false or misleading information;</p>
                    <p>n) Sending unsolicited advertising or spam;</p>
                    <p>o) Employing web crawlers to access our Services;</p>
                    <p>p) Reverse engineering our software or services;</p>
                    <p>q) Conducting unauthorized testing on our API;</p>
                    <p>r) Attempting unauthorized access to our systems;</p>
                    <p>s) Transferring your rights under these Terms;</p>
                    <p>t) Engaging in behavior deemed unacceptable by us.</p>
                    <p>7.4. Digital Assets Wallet. BETTR Trade does not hold custody of the Digital Assets in your Account's wallet. They are stored in the Digital Asset Exchange accessible through your External Exchange Account or Exchange Sub-Account. You are responsible for your Digital Assets' security and any associated risks, including unauthorized access or loss due to password compromise or transfer errors. In case of loss due to transfer errors, such as Blockchain Mismatch, BETTR Trade bears no responsibility and cannot recover lost Digital Assets.</p>
                    <p><b>8. GENERAL USE, PROHIBITED USE, AND TERMINATION</b></p>
                    <p>8.1. Limited License. You are granted a limited, revocable, non-exclusive license to use the Services for personal purposes on your Internet-enabled devices, subject to the terms of this Agreement. This license does not transfer any ownership of the Services or Intellectual Property Rights to you.</p>
                    <p>8.2. Accuracy of Information. While we strive to provide accurate and up-to-date information, the Content may occasionally contain inaccuracies or errors. We may change or update information without notice. You should verify all information before relying on it, and we are not liable for any decisions based on such information. Links to third-party materials are provided for convenience but are not under our control, and we are not responsible for their content.</p>
                    <p>8.3. Third-Party Access. Granting third-party access to your Account does not absolve you of your responsibilities under the Terms. You are accountable for any actions taken by third parties with access to your Account, and you indemnify us from any liability arising from their actions.</p>
                    <p>8.4. Prohibited Use. You agree not to engage in Prohibited Activities while using the Services. We reserve the right to monitor and disclose information as necessary to comply with laws or regulations. We may cancel or suspend your Account if we suspect Prohibited Activity.</p>
                    <p>8.5. Suspension, Termination, and Cancellation.</p>
                    <p>a) We may suspend or terminate your access to the Services if required by law, if we suspect Prohibited Activity, or if there's a legal or regulatory risk associated with your Account. We may also take action if you breach the Terms.</p>
                    <p>b) We may provide notice of such actions unless prohibited by law, but we're not obligated to disclose details of our risk management procedures.</p>
                    <p>c) If you breach the Terms, we may debit trading fee discounts or rebates you received, and we can hold you and any colluding parties jointly liable for damages.</p>
                    <p>d) We reserve the right to investigate violations, determine breaches, and take actions under relevant laws without prior notice.</p>
                    <p>8.6. Relationship of the Parties. This Agreement does not establish a partnership, joint venture, or agency relationship between you and BETTR Trade.</p>
                    <p>8.7. Password Security; Contact Information. You are responsible for safeguarding your login credentials and notifying us of any unauthorized Account activity. We recommend using two-factor authentication for added security.</p>
                    <p>8.9. Taxes. You are responsible for determining and paying applicable taxes on transactions conducted through the Services.</p>
                    <p><b>9. INTELLECTUAL PROPERTY</b></p>
                    <p>9.1. Ownership. All rights pertaining to the Website, Content, User Content, and Services are owned by BETTR Trade. You recognize that BETTR Trade retains ownership of all Intellectual Property Rights in these assets.</p>
                    <p>9.2. Usage Restrictions. You are not permitted to use BETTR Trade's Intellectual Property Rights except as outlined in these Terms, and subject to the following conditions:</p>
                    <p>a) You may only access and use the Services, Website, Content, or User Content for personal use, and you may not distribute, modify, or use them for non-personal, public, or commercial purposes without prior written consent from BETTR Trade.</p>
                    <p>b) You must not remove or alter any copyright, trademark, or other proprietary notices within the Website, Content, User Content, or Services.</p>
                    <p>c) Modifying, publishing, or exploiting any Content or User Content in any manner is prohibited.</p>
                    <p>d) Except where permitted by copyright law, you may not copy, redistribute, or commercially exploit downloaded material without express permission.</p>
                    <p>e) Any permitted copying or redistribution must maintain author attribution, trademark legend, or copyright notice.</p>
                    <p>f) Usage of data mining, robots, or similar methods to gather or extract data is prohibited.</p>
                    <p>9.3. User Content. By submitting User Content, you affirm that you have the necessary Intellectual Property Rights over it. We reserve the right to disclose your identity to any third party claiming violation of their Intellectual Property Rights. You transfer all rights and ownership of your User Content to BETTR Trade, waiving any acknowledgment or compensation requests based on it.</p>
                    <p><b>10. RISK DISCLOSURES</b></p>
                    <p>Trading Digital Assets and/or Digital Asset Derivatives carries inherent risks, and it's essential to only engage with funds you can afford to lose. The risks associated with Digital Assets, Digital Asset Derivatives, and our Services are diverse and include but are not limited to the following:</p>
                    <p>a) Digital Asset prices are highly volatile and subject to numerous factors such as regulatory changes, market manipulation, and issuer actions. By using our Services, you acknowledge and accept these risks. It's your responsibility to assess and understand the risks associated with each transaction.</p>
                    <p>b) We don't provide advice on the suitability of specific Digital Assets or transactions. We are not liable for any losses.</p>
                    <p>c) We don't assure the Signal Providers' reliability. You must conduct your own research and due diligence before following any signals or strategies.</p>
                    <p>d) We don't act as brokers or offer investment advice. Our role is strictly facilitative, and we don't engage in transactions on your behalf.</p>
                    <p>e) Users configuring trading signals should be aware that delays or failures in transmission may occur. Market conditions and technical issues can impact the reliability and effectiveness of signals.</p>
                    <p>f) Past trading performance doesn't guarantee future results. We don't assure profit or success based on historical performance.</p>
                    <p>g) Users are financially responsible for losses resulting from Services.</p>
                    <p>j) We cannot guarantee uninterrupted access to our Services due to factors beyond our control, including regulatory changes and technical issues.</p>
                    <p>l) We don't offer custodial services, and Digital Assets held in your account are maintained on external exchanges. We are not liable for any losses due to exchange security issues or actions.</p>
                    <p>m) While we conduct due diligence on Digital Asset Exchanges, they may operate with varying standards and security measures. Users should be aware of the potential risks associated with trading on unregulated exchanges, including fraud and data inaccuracies.</p>
                    <p><b>11. INDEMNIFICATION</b></p>
                    <p>You agree to hold BETTR Trade and its related entities harmless from any liabilities, losses, damages, costs, and expenses, including legal fees, resulting from your failure to fulfill your obligations under these Terms or any Third Party License, or if any of your representations and warranties are found to be untrue. You also agree to promptly reimburse BETTR Trade for any damages, costs, and expenses, including legal fees, incurred in enforcing these Terms or any other agreements between you and BETTR Trade.</p>
                    <p>Except for limitations of liability stated elsewhere in these Terms, BETTR Trade and its related entities will not be held liable and are released from any claims or losses if:</p>
                    <p>a) Your actions or failure to act caused the claim or loss;</p>
                    <p>b) Any unauthorized access to your account, whether or not authorized by you;</p>
                    <p>c) System malfunctions, equipment failures, interruptions, or unavailability, whether on your or BETTR Trade's end;</p>
                    <p>d) Delays, failures, or errors in executing instructions;</p>
                    <p>e) Inaccurate or incomplete instructions provided by you to BETTR Trade; or</p>
                    <p>f) Your reliance on financial and market data, quotes, news, or any other information available through the trading platform or any Third Party License.</p>
                    <p>BETTR Trade reserves the right to take sole control over the defense of any claim that is subject to indemnification under this section, at your expense.</p>
                    <p><b>12. DISCLAIMER OF WARRANTIES / LIMITATION OF LIABILITY</b></p>
                    <p>12.1 BETTR Trade Entities will not be held responsible for any loss or damage caused by events, actions, or omissions beyond its control, including delays or inaccuracies in order transmission due to breakdowns in communication facilities or power outages.</p>
                    <p>12.2. BETTR Trade, its subsidiaries, affiliates, or agents do not guarantee uninterrupted availability or error-free operation of the Website and/or the Services, which are provided "AS IS" without any representation or warranty, except as otherwise stated herein.</p>
                    <p>12.3. Under no circumstances will BETTR Trade, its subsidiaries, affiliates, or agents be liable for any direct, indirect, punitive, incidental, special, or consequential damages resulting from the use of, or inability to use, the Website and/or Services, including lost profits, business loss, data loss, unauthorized access to computers, or procurement costs.</p>
                    <p>12.4. TO THE FULLEST EXTENT PERMITTED BY LAW, YOU UNDERSTAND AND AGREE THAT IN NO EVENT WILL BETTR Trade ENTITIES AND THEIR RELATED PARTIES BE LIABLE FOR INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL DAMAGES ARISING FROM THE SERVICES, WHETHER UNDER CONTRACT OR OTHERWISE, UNLESS DUE TO GROSS NEGLIGENCE, FRAUD, WILLFUL MISCONDUCT, OR INTENTIONAL VIOLATION OF LAW. HOWEVER, THE LIABILITY OF BETTR Trade ENTITIES ARISING FROM THE SERVICES SHALL NOT EXCEED THE FEES PAID BY YOU TO THEM IN THE TWELVE-MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM FOR LIABILITY. IF YOU ARE DISSATISFIED WITH ANY PART OF THE SERVICES OR THIS AGREEMENT, YOUR SOLE REMEDY IS TO DISCONTINUE USE OF THE SERVICES. THE SERVICES ARE PROVIDED "AS IS" WITHOUT ANY IMPLIED WARRANTIES. BETTR Trade ENTITIES DISCLAIM IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE SERVICES MAY NOT MEET YOUR EXPECTATIONS, AND BETTR Trade ENTITIES DISCLAIM ANY WARRANTIES REGARDING THE SERVICES' MEETING OF REQUIREMENTS, UNINTERRUPTED OPERATION, TIMELINESS, OR ERROR-FREE OPERATION.</p>
                    <p><b>13. NO WAIVER</b></p>
                    <p>Occasionally, BETTR Trade may not insist on strict adherence to any provision in these Terms or may not exercise all of its rights as outlined herein. Such instances of non-enforcement or non-exercise of rights by BETTR Trade shall not be interpreted as a waiver or abandonment of its right to enforce any provision or right in the present or future. Even if BETTR Trade explicitly waives a condition, provision, or requirement of these Terms, it does not waive its right to enforce such condition, provision, or requirement in the future.</p>
                    <p><b>14. GOVERNING LAW AND JURISDICTION</b></p>
                    <p>These Terms, along with any terms posted on the Website, are subject to and shall be interpreted in accordance with the laws of Australia, without considering any conflict of laws principles that might result in the application of laws from another jurisdiction.</p>
                    <p>You consent to resolve any Dispute (as defined below) through arbitration as outlined in Section 16. If the arbitration agreement is deemed invalid or unenforceable, you agree to submit to the non-exclusive jurisdiction of the courts of Australia.</p>
                    <p><b>15. AGREEMENT TO ARBITRATE</b></p>
                    <p>Any disagreement, claim, legal action, lawsuit, cause of legal action, demand, or legal proceeding arising from or connected to these Terms, including any terms published on the Website (such as their validity, existence, or termination), any Services, action, or transaction under or related to these Terms (collectively referred to as a "Dispute"), which remains unresolved between you and BETTR Trade for more than 30 days from the date either party notifies the other party in writing of the Dispute, will be resolved through arbitration. Additionally, the parties agree to the following:</p>
                    <p>a) Before initiating any arbitration or court proceeding, both parties will attempt informal resolution for at least 30 days. These informal negotiations start upon receipt of written notice from you. If the dispute cannot be resolved informally, you and we agree that any dispute under this Agreement will be conclusively resolved through binding arbitration on an individual basis;</p>
                    <p>b) Any dispute, controversy, or claim related to the Terms, or their breach, termination, or invalidity, will be resolved through arbitration in accordance with the rules of the London International Arbitration Centre ("IAC");</p>
                    <p>c) The arbitration will involve one arbitrator;</p>
                    <p>d) The arbitration venue will be the IAC, unless otherwise agreed upon by the Parties;</p>
                    <p>e) English will be the language used in the arbitration proceedings;</p>
                    <p>f) Appeals of arbitration awards and any disputes not subject to arbitration will be under the exclusive jurisdiction of the courts in Australia;</p>
                    <p>g) The arbitrator has the authority to grant any remedy available in court; and</p>
                    <p>h) The parties will share the costs and expenses of arbitration and bear their own legal costs and expenses.</p>
                    <p><b>16. WAIVER OF CLASS ACTION RIGHTS AND CLASS WIDE ARBITRATION</b></p>
                    <p>You and BETTR Trade acknowledge that any claims related to these Terms or to your association with BETTR Trade as a user of the Services (regardless of whether based on contract, tort, statute, fraud, misrepresentation, or any other legal theory, and whether these claims arise during or after the conclusion of these Terms) shall be addressed against the other party through arbitration on an individual basis only, and not as a plaintiff or member of a class in an alleged class or representative proceeding. You and BETTR Trade also agree to forfeit any entitlement for such claims to be pursued, heard, or arbitrated as part of a class, collective, representative, or private attorney general action, to the extent permitted by applicable law. You pledge not to collaborate with any other individual, entity, or group of individuals or entities to settle the respective disputes on a consolidated or representative basis.</p>
                    <p><b>17. PRIVACY POLICY</b></p>
                    <p>The Privacy Policy of BETTR Trade can be found <Link to="/page-privacy-policy">here</Link>.</p>
                    <p><b>18. MISCELLANEOUS</b></p>
                    <p>18.1. Assignment. You are not permitted to transfer any rights or licenses granted under these Terms. However, we reserve the right to transfer our rights without restriction, including to any of our affiliates, subsidiaries, or any successor associated with the Services. Any attempt to transfer or assign in violation of this provision will be deemed invalid. Nonetheless, this Agreement will apply to and benefit the parties, their successors, and permitted assigns.</p>
                    <p>18.2. Severability. If any provision of the Terms is found to be invalid or unenforceable under any law or regulation, it will be interpreted to fulfill the objectives of the provision to the fullest extent possible under the law. The validity or enforceability of any other provision of the Terms will not be affected.</p>
                    <p>18.3. Force Majeure. We will not be held liable for any failure or delay resulting from circumstances beyond our control, including governmental actions, acts of terrorism, natural disasters, labor conditions, system failures, or other legal obligations.</p>
                    <p>18.4. Survival. Upon termination of your account or this Agreement for any reason, any rights and obligations of the parties that are ongoing will continue.</p>
                    <p>18.5. Third Party Rights. The Terms do not create rights or remedies for any parties other than you and us, and any affiliates, which are considered third-party beneficiaries. No other person may claim rights as a third-party beneficiary under these Terms.</p>
                    <p>BY AGREEING TO THESE TERMS, THE USER ACKNOWLEDGES HAVING RECEIVED, READ, AND UNDERSTOOD THEM AND AGREES TO BE BOUND BY ALL TERMS AND CONDITIONS HEREIN.</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
